<div class="supplier-overview-toolbar">
    <app-square-button
      *ngFor="let homeButton of homeButtons"
      [title]="homeButton.title"
      [task]="homeButton.task"
      [ident]="homeButton.ident"
      [iconType]="homeButton.icon"
      [description]="homeButton.description"
      [cssStyle]="'height: 100%;'"
    ></app-square-button>
  </div>