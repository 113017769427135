import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from "lodash";

@Pipe({
  name: "topicFilter",
})
export class TopicFilterPipe implements PipeTransform {
  transform(categories?: any[], topic?: string): any {
    if (categories && categories.length > 0) {
      if (topic && topic.length > 0) {
        categories = orderBy(
          categories.filter((c) => c.topic === topic),
          ["name"],
          ["asc"]
        );
      }
    }
    return categories;
  }
}
