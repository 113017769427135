import { Pipe, PipeTransform } from '@angular/core';
import { IButton } from 'src/app/components/buttons/button/button.component';

@Pipe({
  name: 'filterHiddenButtons'
})
export class FilterHiddenButtonsPipe implements PipeTransform {

  transform(values: IButton[], ...args: any[]): IButton[] {
    return values && values.length > 0 ? values.filter((v) => !(v.hidden === true)) : [];
  }

}
