<div class="css-info-panel">
  <div class="css-info-progressbar">
    <app-progress-bar
      [fillLevel]="progressValue"
      [showFillLevel]="true"
      style="width: inherit"
    ></app-progress-bar>
  </div>
  <div class="css-info-description">
    <span>{{ infoDescription | translate }}</span>
  </div>
  <span *ngIf="lastSaved" class="last-saved-info"
    >{{ "screen.savedLabel" | translate }}:
    {{ lastSaved | date : "dd.MM.yyyy HH:mm" }}</span
  >
</div>
