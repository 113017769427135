import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { Logger } from "src/app/logger";
import { environment } from "src/environments/environment";
import { JwtTokenService } from "./jwt-token.service";
import { LocalStorageService } from "./local-storage.service";

const USER_API = environment.API_ENDPOINT + "/user/";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private appComponent?: AppComponent;

  public static TOKEN_KEY = "token";

  logger = new Logger("AuthService");

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private jwtTokenService: JwtTokenService
  ) {
    let token = localStorageService.get(AuthService.TOKEN_KEY);
    if (token) {
      this.jwtTokenService.setToken(token);
    }
  }

  async login(username: string, password: string) {
    let res: any = await this.http
      .post(USER_API + "login", {
        username,
        password,
      })
      .toPromise();

    this.logger.log("[login] logged in", res);

    let { user, token } = res;
    this.localStorageService.set(AuthService.TOKEN_KEY, token);
    this.jwtTokenService.setToken(token);

    if (this.appComponent) {
      this.appComponent.isLoggedIn = true;
    }
  }

  logout() {
    this.localStorageService.remove(AuthService.TOKEN_KEY);
    this.jwtTokenService.clearToken();

    if (this.appComponent) {
      this.appComponent.isLoggedIn = false;
    }
  }

  setAppComponent(appComponent: AppComponent) {
    this.appComponent = appComponent;
  }
}
