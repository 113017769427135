import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { SUPPLIER_EMPLOYEES_FORM } from "src/app/controller/constants/forms-config";
import { AbstractFormComponent } from "../abstract-form.component";

@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.css"],
  host: {
    class: "css-form",
  },
})
export class EmployeesComponent
  extends AbstractFormComponent
  implements OnInit
{
  form: any = {
    amount: null,
    production: null,
    administration: null,
    qualitymanagement: null,
    sales: null,
    purchase: null,
  };

  constructor(http: HttpClient) {
    super(http);
  }

  ID: number = SUPPLIER_EMPLOYEES_FORM.ID;
  TITLE: string = SUPPLIER_EMPLOYEES_FORM.TITLE;
  PATH: string = SUPPLIER_EMPLOYEES_FORM.PATH;

  getFormValues() {
    return this.form;
  }

  setFormValues(formValues: any): void {
    this.form = formValues;
  }

  getSheetName(): string {
    return "employees";
  }

  ngOnInit(): void {}
}
