import { Component, Input } from '@angular/core';
import { Task } from "src/app/tasks/task";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent {

  @Input()
  backButtonLabel: string = "";

  @Input()
  backButtonTask?: Task;

  @Input()
  title: string = "";

  @Input()
  description: string = "";
}
