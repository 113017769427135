import { Component, Input, OnInit } from '@angular/core';
import { Task } from 'src/app/tasks/task';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.css']
})
export class HeaderPanelComponent implements OnInit {

  @Input()
  backTask: Task | null = null;

  @Input()
  homeTask?: Task;

  @Input()
  progressValue: number = 0;

  @Input()
  infoDescription: string = "";

  @Input()
  lastSaved?: Date;

  @Input()
  lastFinalization?: Date

  constructor() { }

  ngOnInit(): void {
  }

}
