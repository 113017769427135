<mat-dialog-content>
  <div class="certificates-container">
    <h1>{{ title | translate }}</h1>
    <app-css-text-input
      *ngIf="dialogType === 'add'"
      [label]="'screen.certificates.name'"
      [(value)]="certificate.name"
      style="display: block;margin-bottom: 1em;"
    ></app-css-text-input>
    <h2>Gültigkeit</h2>
    <div class="certificates-validation-dates-container">
      <span>Gültig bis:</span>
      <div
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-end;
        "
      >
        <app-css-text-input
          (valueChange)="validUntilDateChanged($event, 'MM')"
          [value]="
            certificate.validUntilDate && certificate.validUntilDate.length > 0
              ? certificate.validUntilDate.split('.')[0]
              : null
          "
          [minValue]="1"
          [maxValue]="12"
          [type]="'number'"
          [placeholder]="'MM'"
          style="width: 6em"
        ></app-css-text-input>
        <app-css-text-input
          (valueChange)="validUntilDateChanged($event, 'YYYY')"
          [value]="
            certificate.validUntilDate && certificate.validUntilDate.length > 0
              ? certificate.validUntilDate.split('.')[1]
              : null
          "
          [minValue]="2023"
          [type]="'number'"
          [placeholder]="'JJJJ'"
          style="width: 7em"
        ></app-css-text-input>
      </div>
    </div>
    <h2>Dateien</h2>
    <div class="certificates-files">
      <div
        class="certificates-file-container"
        *ngFor="let file of certificate.files"
      >
        <span class="ml-1">{{ file.filename }}</span>
        <span>{{ file.lastModifiedDate | date : "dd.MM.yyyy" }}</span>
        <div class="certificates-file-actions">
          <!-- <app-button [iconType]="downloadFileBtnConfig.iconType"></app-button> -->
          <app-button
            [iconType]="removeFileBtnConfig.iconType"
            (click)="removeFile(file)"
          ></app-button>
        </div>
      </div>
      <div
        class="certificates-file-container"
        *ngIf="certificate.files.length == 0"
      >
        <span>{{ "screen.certificates.no-files-uploaded" | translate }}</span>
      </div>
    </div>
    <h2>Hochladen</h2>
    <div class="certificates-upload-container">
      <app-upload-files
        (uploadFinished)="onUploadFinished($event)"
        [fileName]="certificate.name"
        [fileIdent]="certificate.ident"
      ></app-upload-files>
    </div>
    <p
      *ngIf="missingRequiredFields"
      style="font-style: italic; color: rgb(192, 0, 0); max-width: 450px"
    >
      {{ "screen.certificates.invalid-information-for-saving" | translate }}
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="custom-mat-dialog-actions">
  <div class="modal-dialog-footer" *ngIf="!taskLoading">
    <app-button
      [title]="'comp.title.save'"
      [cssStyle]="'height: 100%;align-items: center;'"
      (click)="saveCertificate()"
    ></app-button>
    <app-button
      *ngFor="let button of buttons"
      [btnType]="button.type"
      [dialogRef]="dialogRef"
      [task]="button.task"
      [title]="button.text"
      [customClasses]="'css-modal-button'"
    ></app-button>
  </div>
  <div class="modal-dialog-footer" *ngIf="taskLoading">
    <button class="btn btn-custom btn-outline">
      <div class="spinner-border spinner-border-sm"></div>
    </button>
  </div>
</mat-dialog-actions>
