import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SupplierFormController } from "src/app/controller/supplier-form.controller";
import { FormServiceService } from "src/app/services/form-service.service";
import { AbstractFormComponent } from "./abstract-form.component";
import { AssessmentService } from "src/app/services/api/assessment.service";

@Component({
  selector: "app-supplier-form",
  templateUrl: "./supplier-form.component.html",
  styleUrls: ["./supplier-form.component.css"],
})
export class SupplierFormComponent implements OnInit {
  controller!: SupplierFormController;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formService: FormServiceService,
    private translateService: TranslateService,
    private assessmentService: AssessmentService,
  ) {
    this.controller = new SupplierFormController(dialog, router, formService, translateService, assessmentService);
  }

  routeActivated(component: any) {
    console.log("routeActivated", component);
    if (component instanceof AbstractFormComponent) {
      this.formService.updateCurrentForm(component);
    }
  }

  routeDeactivated(event: any) {
    console.log("routeDeactivated", event);
  }

  ngOnInit(): void {}
}
