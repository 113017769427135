import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Logger } from "src/app/logger";

@Component({
  selector: "app-css-radio-button",
  templateUrl: "./css-radio-button.component.html",
  styleUrls: ["./css-radio-button.component.css"],
})
export class CssRadioButtonComponent implements OnInit {
  logger = new Logger("CssRadioButtonComponent");

  @Input()
  options: any[] = [];

  @Input()
  radioName: string = "";

  @Input()
  title?: string;

  @Input()
  value?: any;

  @Output()
  valueChange = new EventEmitter<any>();

  constructor() {}

  valueChanged(event: any, option: any) {
    this.value = option;
    this.logger.log("valueChanged", this.value);
    this.valueChange.emit(this.value);
  }

  ngOnInit(): void {}
}
