import { MatDialogRef } from "@angular/material/dialog";
import { ModalComponent } from "src/app/modal/modal.component";
import { OnCancelTask } from "src/app/tasks/on-error-task";
import { Task } from "../task";

export class CancelTask implements Task {

  constructor(private delegate?: OnCancelTask){}

  async execute(dialogRef?: MatDialogRef<ModalComponent>): Promise<void> {
    this.delegate?.onCancel();
    dialogRef?.close(true);
    console.log('Executed ' + CancelTask.name);
  }

}
