<div class="css-radio-button-outer-container">
  <span *ngIf="title" class="css-radio-button-container-title">{{
    title | translate
  }}</span>
  <div action="" class="css-radio-button-container">
    <label *ngFor="let option of options" class="css-radio-button">
      <input
        type="radio"
        [name]="radioName"
        [id]="option.id"
        [value]="option"
        [checked]="value?.id === option.id"
        (change)="valueChanged($event, option)"
      />
      {{ option.name }}
    </label>
  </div>
</div>
