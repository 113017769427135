import { environment } from "src/environments/environment";

export class Logger {
  constructor(private name?: string) {}

  log(msg: any, ...optionalParams: any[]) {
    if (!environment.production) {
      console.log(`[${this.name}] ` + msg, optionalParams);
    }
  }
}
