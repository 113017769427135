<button
  [ngClass]="getBtnClass()"
  tabindex="1"
  [style]="cssStyle"
  [routerLink]="route"
  [disabled]="taskLoading"
>
  <i class="fa fa-chevron-left"></i>
  {{ title | translate }}
</button>
