<p style="width: 100%; text-align: center">
  Bitte wählen Sie alle relevanten Ansprechpartner und Kontaktdaten oder fügen
  Sie neue Daten hinzu.
</p>
<app-spinner [hidden]="!loadingFields"></app-spinner>
<div class="contacts-component-container">
  <div *ngIf="!activeContactCategory" class="contacts-container">
    <div
      *ngFor="let contactCategory of contactCategories; index as i"
      style="display: flex; flex-direction: row"
    >
      <app-button
        style="flex-grow: 1"
        [enableForwardIcon]="true"
        [title]="
          contactCategory.ident.startsWith('custom-')
            ? contactCategory.name
            : 'screen.contacts-labels.' + contactCategory.ident
        "
        (click)="selectContactCategory(contactCategory)"
      ></app-button>
      <app-button
        *ngIf="contactCategory.ident.startsWith('custom-')"
        [btnType]="removeContactCategoryBtn.btnType"
        [iconType]="removeContactCategoryBtn.iconType"
        (click)="removeContactCategory(i)"
        class="h-100 ml-1"
        [customClasses]="'h-100'"
      ></app-button>
    </div>
    <div class="add-contact-category-container">
      <app-css-text-input
        [(value)]="newContactCategory"
        class="w-100"
      ></app-css-text-input>
      <app-button
        [title]="addContactCategoryBtn.title"
        [btnType]="addContactCategoryBtn.btnType"
        (click)="addContactCategory()"
      ></app-button>
    </div>
  </div>
  <div *ngIf="activeContactCategory" class="contacts-container">
    <div class="active-contact-category-header">
      <app-button
        [enableBackwardIcon]="true"
        [cssStyle]="'width: 60px;'"
        (click)="selectContactCategory(null)"
      ></app-button>
      <div class="active-contact-category-header-container">
        <span class="active-contact-category-header-title">{{
          activeContactCategory.name
        }}</span>
      </div>
    </div>
    <div>
      <div
        *ngFor="let person of activeContactCategory.persons; index as i"
        class="contact-person-container"
      >
        <app-button
          class="w-100"
          [title]="getPersonBtnTitle(person)"
          (click)="raiseEditPersonDialog(person, i)"
        ></app-button>
        <app-button
          [iconType]="removePersonBtn.iconType"
          (click)="removePerson(i)"
        ></app-button>
      </div>
      <app-button
        [customClasses]="'mt-2'"
        [iconType]="addContactCategoryBtn.iconType"
        [title]="addContactCategoryBtn.title"
        [btnType]="addContactCategoryBtn.btnType"
        (click)="raiseAddPersonDialog()"
      ></app-button>
    </div>
  </div>
</div>
