import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Logger } from "src/app/logger";

@Component({
  selector: "app-css-select",
  templateUrl: "./css-select.component.html",
  styleUrls: ["./css-select.component.css"],
})
export class CssSelectComponent implements OnInit {
  logger = new Logger();

  @Input()
  options: any[] = [];

  @Input()
  label: string = "";

  @Input()
  name: string = "";

  @Input()
  value: string = "";

  @Output()
  valueChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  selectionChanged(e: any) {
    this.logger.log("selection changed", e.target.value);
    this.valueChange.emit(e.target.value);
  }
}
