<app-toolbar [backButtonLabel]="backButtonLabel" [backButtonTask]="navigateBackTask" [title]="title" [description]="description" ></app-toolbar>
<div *ngIf="selectedAssessment == null" class="open-feedbacks-container">
  <!-- The AG Grid component -->
  <ag-grid-angular
    class="ag-theme-alpine"
    style="height: 500px"
    [rowData]="rowData"
    [columnDefs]="colDefs"
  >
  </ag-grid-angular>
</div>
<app-assessment-detail [screenMode]="'audit'" [assessments]="assessmentForComparison" *ngIf="selectedAssessment != null" (auditEvent)="onAuditEvent($event)"></app-assessment-detail>
