import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ContactsComponent } from "src/app/screens/supplier-form/contacts/contacts.component";
import { ModalComponent } from "../../modal.component";

export interface ModalComponentData {
  source: ContactsComponent;
  dialogType: string;
  countryCodes: any[];
  person: any;
  index: number;
}

export enum PersonModalDialogType {
  ADD = "add",
  EDIT = "edit",
}

@Component({
  selector: "app-person-modal",
  templateUrl: "./person-modal.component.html",
  styleUrls: ["./person-modal.component.css"],
})
export class PersonModalComponent implements OnInit {
  title: string = "";
  taskLoading: boolean = false;
  source!: ContactsComponent;
  countryCodes: any[] = [];
  cancelButtonConfig = {
    btnType: ButtonType.SECONDARY,
    title: "general.close",
  };
  saveButtonConfig = {
    btnType: ButtonType.PRIMARY,
    title: "comp.title.save",
  };
  dialogType: string | null = null;
  index: number = 0;
  missingRequiredFields: boolean = false;

  titleValues = [
    {
      value: null,
      label: "-",
    },
    {
      value: "dr",
      label: "screen.contacts-labels.title-values.dr",
    },
  ];

  genderValues = [
    {
      value: "male",
      label: "screen.contacts-labels.gender-values.male",
    },
    {
      value: "female",
      label: "screen.contacts-labels.gender-values.female",
    },
    {
      value: "divers",
      label: "screen.contacts-labels.gender-values.divers",
    },
  ];

  person: any = {
    position: null,
    title: "",
    gender: "",
    fName: null,
    lName: null,
    phone: "+49",
    mail: null,
  };

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ModalComponentData
  ) {
    if (data) {
      this.title = `screen.contacts-labels.person-modal.${data.dialogType}`;
      this.countryCodes = data.countryCodes;
      this.source = data.source;
      this.dialogType = data.dialogType;
      if (data.dialogType === PersonModalDialogType.EDIT) {
        this.person = JSON.parse(JSON.stringify(data.person)); // make a copy
        this.index = data.index;
      }
    }
  }

  savePerson() {
    // check required fields
    let { position, fName, lName } = this.person;
    if (
      !position ||
      position.length == 0 ||
      !fName ||
      fName.length == 0 ||
      !lName ||
      lName.length == 0
    ) {
      this.missingRequiredFields = true;
      return;
    }

    switch (this.dialogType) {
      case PersonModalDialogType.ADD:
        this.source.addPerson(this.person);
        break;
      case PersonModalDialogType.EDIT:
        this.source.editPerson(this.person, this.index);
        break;

      default:
        break;
    }

    this.closeDialog();
  }

  closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
    console.log("openend dialog");
  }

  enableTaskLoading(loading: boolean) {
    this.taskLoading = loading;
  }
}
