import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.css"],
})
export class ProgressBarComponent implements OnInit {
  @Input()
  fillLevel = 0;

  @Input()
  showFillLevel = false;

  constructor() {}

  ngOnInit(): void {}

  getStyle(): any {
    let style: any = {};
    style["width"] = `${this.fillLevel}%`;
    return style;
  }
}
