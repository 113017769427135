import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { JwtTokenService } from "../service/jwt-token.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private jwtTokenService: JwtTokenService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.jwtTokenService.jwtToken;
    if (token) {
      request = request.clone({
        headers: request.headers.set("x-access-token", token),
      });
    }
    return next.handle(request);
  }
}
