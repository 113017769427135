<div class="supplier-admin-toolbar-container">
  <app-button
    [title]="backButtonLabel"
    [enableBackwardIcon]="true"
    [task]="backButtonTask"
  ></app-button>

  <div>
    <div class="supplier-admin-toolbar-info">
      <span>{{ title | translate }}</span>
      <p>{{ description | translate }}</p>
    </div>
  </div>
</div>
