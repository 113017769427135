
export const OPENFEEDBACKS = {
  ID: "open-feedbacks",
  PATH: "open-feedbacks",
  TITLE: "screen.admin.overview.open-feedbacks",
  DESCRIPTION: "screen.admin.overview.open-feedbacks-description",
};
export const SUPPLIERADMINOVERVIEW = {
  ID: "supplier-overview",
  PATH: "supplier-overview",
  TITLE: "screen.admin.overview.suppliers",
  DESCRIPTION: "screen.admin.overview.suppliers-description",
};
export const FEEDBACKDETAILOVERVIEW = {
  ID: "feedback-detail",
  PATH: "feedback-detail",
  TITLE: "Lieferantenprüfung",
  DESCRIPTION: "Prüfung des letzten übermittelten Fragebogen",
};
export const USERMANAGEMENT = {
  ID: "user-management",
  PATH: "user-management",
  TITLE: "screen.admin.user-management.title",
  DESCRIPTION: "screen.admin.user-management.description",
};

export const MODES = [OPENFEEDBACKS, SUPPLIERADMINOVERVIEW, FEEDBACKDETAILOVERVIEW];
export const getAdminScreenById = (id: string) => {
  return MODES.find((m) => m.ID === id);
};