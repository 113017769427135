import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "src/app/modal/modal.component";
import { Task } from "../task";
import { NestedNavigateTask } from "./nested-navigate.task";

export class NavigateWithConditionTask implements Task {
  constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected route: any[],
    protected dialogMessage: string,
    protected translateService: TranslateService,
    protected saveTask: Task | null,
    protected condition: () => boolean
  ) {}

  async execute(dialogRef?: MatDialogRef<ModalComponent>): Promise<void> {
    if (this.condition()) {
      await this.router.navigate(this.route);
    } else {
      let successTask = new NavigateWithConditionTask(
        this.dialog,
        this.router,
        this.route,
        "",
        this.translateService,
        null,
        () => true
      );

      let cancelBtn = ModalButtonGenerator.createCancelButton();
      let btns = [cancelBtn];

      if (this.saveTask != null) {
        let confirmAndSaveBtn = ModalButtonGenerator.createButton(
          "general.yes",
          new NestedNavigateTask(this.saveTask, this.router, this.route),
          ButtonType.PRIMARY
        );
        let confirmWithoutSaveBtn = ModalButtonGenerator.createButton(
          "general.no",
          successTask,
          ButtonType.SECONDARY
        );
        btns.unshift(confirmWithoutSaveBtn);
        btns.unshift(confirmAndSaveBtn);
      } else {
        let confirmBtn = ModalButtonGenerator.createOkButton(successTask);
        btns.unshift(confirmBtn);
      }

      const dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message: this.translateService.instant(this.dialogMessage),
          buttons: btns,
        },
      });

      await dialogRef.afterClosed().toPromise()
      console.log("[NavigateWithConditionTask] The dialog was closed");
    }
    dialogRef?.close(true);
  }
}
