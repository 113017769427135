import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { ButtonComponent } from "../../button/button.component";

@Component({
  selector: "app-cell-button",
  templateUrl: "./cell-button.component.html",
  styleUrls: ["./cell-button.component.css"],
})
export class CellButtonComponent
  extends ButtonComponent
  implements ICellRendererAngularComp
{
  params: any;
  _mode: 0 | 1 = 0;

  directSave: boolean = false;

  set mode(val: 0 | 1) {
    this._mode = val;
  }

  get mode() {
    return this._mode;
  }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.title = this.params["title"];
    this.directSave = this.params["directSave"];
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  cancel() {
    this.mode = 0;
    this.onCellClick(CellButtonAction.CANCEL);
  }

  save() {
    this.mode = 0;
    this.onCellClick(CellButtonAction.SAVE);
  }

  edit() {
    if (this.directSave) {
      this.save();
      return;
    }
    this.mode = 1;
    this.onCellClick(CellButtonAction.EDIT);
  }

  private onCellClick(action: CellButtonAction) {
    if (this.params && this.params.onCellClick instanceof Function) {
      this.params.onCellClick(action, this.params);
    }
  }
}
export enum CellButtonAction {
  CANCEL = "cancel",
  SAVE = "save",
  EDIT = "edit",
}
