<app-toolbar
  [backButtonLabel]="backButtonLabel"
  [backButtonTask]="navigateBackTask"
  [title]="title"
  [description]="description"
></app-toolbar>
<div *ngIf="selectedAssessment == null" class="open-feedbacks-container">
  <!-- The AG Grid component -->
  <ag-grid-angular
    class="ag-theme-alpine"
    style="height: 500px"
    [rowData]="rowData"
    [columnDefs]="colDefs"
  >
  </ag-grid-angular>
</div>
<div
  style="display: flex; flex-direction: column"
  *ngIf="auditedAssessment['assessment_to_compare'] != null"
>
  <h4>{{ "screen.admin.supplier-overview.sync" | translate }}</h4>
  <p>{{ "screen.admin.supplier-overview.sync-description" | translate }}</p>
  <div style="display: flex; flex-direction: row; gap: 0.5em">
    <app-css-text-input
      [(value)]="eapNoValue"
      [label]="
        'screen.admin.supplier-overview.sync-input-placeholder' | translate
      "
    ></app-css-text-input>
    <app-button
      [title]="'screen.admin.supplier-overview.sync-action'"
      [task]="syncEAPTask"
      style="display: flex; flex-direction: column; justify-content: flex-end"
    ></app-button>
  </div>
  <h4 style="margin-top: 1em">
    {{ "screen.admin.supplier-overview.data" | translate }}
  </h4>
  <app-assessment-detail
    [screenMode]="'display-only'"
    [assessments]="auditedAssessment"
  ></app-assessment-detail>
</div>
