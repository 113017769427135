import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Logger } from "src/app/logger";

@Component({
  selector: "app-css-checkbox",
  templateUrl: "./css-checkbox.component.html",
  styleUrls: ["./css-checkbox.component.css"],
})
export class CssCheckboxComponent implements OnInit {
  logger = new Logger("CssCheckboxComponent");

  @Input()
  value?: any;

  @Output()
  valueChange = new EventEmitter<any>();

  @Input()
  disabled: boolean = false;

  constructor() {}

  onValueChange(event: any) {
    this.value["value"] = event.target.checked;
    this.logger.log("valueChanged", this.value);
    this.valueChange.emit(this.value);
  }

  ngOnInit(): void {}
}
