import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/service/auth.service";
import { SUPPLIER_ASSESSMENT_FINALIZATION } from "src/app/controller/constants/forms-config";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "src/app/modal/modal.component";
import { AssessmentService } from "src/app/services/api/assessment.service";
import { CancelTask } from "src/app/tasks/impl/cancel.task";
import { AbstractFormComponent } from "../abstract-form.component";

@Component({
  selector: "app-finalization",
  templateUrl: "./finalization.component.html",
  styleUrls: ["./finalization.component.css"],
  host: {
    class: "css-form",
  },
})
export class FinalizationComponent extends AbstractFormComponent {
  ID: number = SUPPLIER_ASSESSMENT_FINALIZATION.ID;
  TITLE: string = SUPPLIER_ASSESSMENT_FINALIZATION.TITLE;
  PATH: string = SUPPLIER_ASSESSMENT_FINALIZATION.PATH;

  constructor(
    http: HttpClient,
    public translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private assessment_service: AssessmentService,
    private auth_service: AuthService
  ) {
    super(http);
  }

  confirmation = {
    value: false,
    label: this.translate.instant("screen.finalization-labels.checkbox"),
  };

  form: any = {};

  finalizeBtnConfig: any = {
    cssStyle: 'height: 100%;align-items: center;',
    title: 'screen.finalization-labels.confirmation_button',
    iconType: ButtonIconType.UPLOAD
  }

  async finalizeAssessment() {
    if (!this.confirmation["value"]) {
      this.dialog.open(ModalComponent, {
        data: {
          message: this.translate.instant("screen.finalization_confirm_dialog"),
          buttons: [
            ModalButtonGenerator.createButton(
              "Ok",
              new CancelTask(),
              ButtonType.PRIMARY
            ),
          ],
        },
      });
    } else {
      await this.assessment_service.finalizeAssessment().toPromise();
      this.auth_service.logout();
      this.router.navigate(["finalizationEnd"]);
    }
  }

  confirmationChanged(confirmation: any) {
    return;
  }

  getFormValues() {
    return this.form;
  }

  setFormValues(formValues: any): void {
    this.form = formValues;
  }

  getSheetName(): string {
    return "finalization";
  }
}
