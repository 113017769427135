import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import { CellActionsComponent } from "src/app/components/buttons/ag-grid/cell-actions/cell-actions.component";
import { ButtonComponent, IButton } from "src/app/components/buttons/button/button.component";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ISupplierAdminScreen } from "src/app/interfaces/supplier-admin-mode.interface";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "src/app/modal/modal.component";
import { AssessmentService } from "src/app/services/api/assessment.service";
import { Task } from "src/app/tasks/task";

@Component({
  selector: "app-assessment-detail",
  templateUrl: "./assessment-detail.component.html",
  styleUrls: ["./assessment-detail.component.css"],
})
export class AssessmentsDetailComponent
  implements OnInit, ISupplierAdminScreen
{
  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [];
  colDefsGeneral: ColDef[] = [];
  colDefsEmployees: ColDef[] = [];
  colDefsContacts: ColDef[] = [];
  colDefsQualityManagement: ColDef[] = [];
  colDefsProductionSpectrum: ColDef[] = [];

  rowDataGeneral: any[] = [];
  rowDataEmployees: any[] = [];
  rowDataContacts: any[] = [];
  rowDataQualityManagement: any[] = [];
  rowDataProductionSpectrum: any[] = [];

  auditAssessmentTask: Task;
  auditFinalizeAssessmentTask: Task;

  auditAssessmentBtnConfig: any = {
    btnType: ButtonType.SECONDARY,
    btnIcon: ButtonIconType.SAVE,
    title: "general.intermediate-save",
  };

  auditFinalizeAssessmentBtnConfig: any = {
    btnType: ButtonType.PRIMARY,
    btnIcon: ButtonIconType.CHECK,
    title: "screen.admin.feedback-detail.audit-finalize-assessment",
  };

  assessmentComments: string = "";

  private _assessments: any = {};

  @Input()
  set assessments(val: any) {
    console.log("assessment selected", val);
    let assessmentToUse = this.screenMode == "audit" ? val["assessment_to_audit"] : val["assessment_to_compare"];
    this.rowDataGeneral = this.getCompareData(
      val["assessment_to_compare"],
      val["assessment_to_audit"],
      "general"
    );
    this.rowDataEmployees = this.getCompareData(
      val["assessment_to_compare"],
      val["assessment_to_audit"],
      "employees"
    );
    this.rowDataContacts = this.getContactsData(assessmentToUse);
    this.rowDataQualityManagement = this.getQualityManagementData(
      assessmentToUse
    );
    this.rowDataProductionSpectrum = this.getProductionSpectrumData(assessmentToUse);
    this._assessments = val;

    if (
      assessmentToUse &&
      assessmentToUse["audit_comments"]
    ) {
      this.assessmentComments = assessmentToUse["audit_comments"];
    }
  }

  get assessments() {
    return this._assessments;
  }

  private _screenMode: "audit" | "display-only" = "display-only";

  @Input()
  set screenMode(val: "audit" | "display-only") {
    this._screenMode = val;
  }

  @Output()
  auditEvent = new EventEmitter<any>();

  get screenMode() {
    return this._screenMode;
  }

  ID = "feedback-detail";
  PATH = "feedback-detail";
  title = "Lieferantenprüfung";
  description = "Prüfung des letzten übermittelten Fragebogen";
  comp = AssessmentsDetailComponent.name;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private assessmentService: AssessmentService
  ) {
    this.auditAssessmentTask = new AuditAssessmentTask(
      this,
      false,
      dialog,
      translate,
      true,
      assessmentService
    );
    this.auditFinalizeAssessmentTask = new AuditFinalizeAssessmentTask(
      this,
      dialog,
      translate,
      assessmentService
    );
  }

  navigateBack(): void {
    //
  }

  ngOnInit(): void {
    this.colDefs = [
      {
        headerName: this.headerTranslation("supplier"),
        field: "supplier",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("last-audit"),
        field: "lastAudit",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("actions"),
        field: "actions",
        flex: 1,
      },
    ];

    this.colDefsGeneral = [
      {
        field: "field",
        hide: true,
      },
      {
        headerName: this.headerTranslation("field"),
        field: "fieldTranslated",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("old-value"),
        field: "oldValue",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("new-value"),
        hide: this.screenMode == "display-only",
        field: "newValue",
        editable: this.screenMode == "audit",
        flex: 1,
        cellStyle: (params) => {
          let style: any = {};
          if (this.screenMode == "audit") {
            style["borderBottom"] = "1px solid rgb(61 61 61 / 70%)";
          }
          if (params.data["oldValue"] != params.data["newValue"]) {
            style["backgroundImage"] =
              "linear-gradient(26deg, #ffff3b, rgb(255, 255, 172))";
          }
          return style;
        },
      },
    ];

    this.colDefsEmployees = [
      {
        field: "field",
        hide: true,
      },
      {
        headerName: this.headerTranslation("field"),
        field: "fieldTranslated",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("old-value"),
        field: "oldValue",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("new-value"),
        hide: this.screenMode == "display-only",
        field: "newValue",
        editable: this.screenMode == "audit",
        flex: 1,
        cellStyle: (params) => {
          let style: any = {};
          if (this.screenMode == "audit") {
            style["borderBottom"] = "1px solid rgb(61 61 61 / 70%)";
          }
          if (params.data["oldValue"] != params.data["newValue"]) {
            style["backgroundImage"] =
              "linear-gradient(26deg, #ffff3b, rgb(255, 255, 172))";
          }
          return style;
        },
      },
    ];

    this.colDefsContacts = [
      {
        headerName: this.headerTranslation("contacts-area"),
        field: "name",
        flex: 1,
      },
      {
        headerName: this.fieldTranslation("contacts", "position"),
        valueGetter: "data.person.position",
        flex: 1,
      },
      {
        headerName: this.fieldTranslation("contacts", "title"),
        valueGetter: "data.person.title",
        width: 70,
        cellRenderer: (params: any) => {
          if (params.value !== "dr") {
            params.value = "empty";
          }

          return this.translate.instant(
            `screen.contacts-labels.title-values.${params.value}`
          );
        },
      },
      {
        headerName: this.fieldTranslation("contacts", "gender"),
        valueGetter: "data.person.gender",
        width: 85,
        cellRenderer: (params: any) => {
          return this.translate.instant(
            `screen.contacts-labels.gender-values.${params.value}`
          );
        },
      },
      {
        headerName: this.fieldTranslation("contacts", "fName"),
        valueGetter: "data.person.fName",
        flex: 1,
      },
      {
        headerName: this.fieldTranslation("contacts", "lName"),
        valueGetter: "data.person.lName",
        flex: 1,
      },
      {
        headerName: this.fieldTranslation("contacts", "phone"),
        valueGetter: "data.person.phone",
        valueSetter: (params) => {
          params.data.person.phone = params.newValue;
          return true;
        },
        editable: this.screenMode == "audit",
        cellStyle: (params) => {
          let style: any = {};
          if (this.screenMode == "audit") {
            style["borderBottom"] = "1px solid rgb(61 61 61 / 70%)";
          }
          return style;
        },
        flex: 1,
      },
      {
        headerName: this.fieldTranslation("contacts", "mail"),
        valueGetter: "data.person.mail",
        valueSetter: (params) => {
          params.data.person.mail = params.newValue;
          return true;
        },
        editable: this.screenMode == "audit",
        cellStyle: (params) => {
          let style: any = {};
          if (this.screenMode == "audit") {
            style["borderBottom"] = "1px solid rgb(61 61 61 / 70%)";
          }
          return style;
        },
        flex: 1,
      },
    ];

    this.colDefsQualityManagement = [
      {
        headerName: this.headerTranslation("quality-management-identifier"),
        field: "ident",
        cellRenderer: (params: any) => {
          if (params.value && params.value.startsWith("custom-")) {
            return params.data["name"];
          }

          return this.translate.instant(`screen.certificates.${params.value}`);
        },
        flex: 1,
      },
      {
        headerName: this.headerTranslation("quality-management-valid-date"),
        field: "validUntilDate",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("quality-management-file"),
        valueGetter: "data.file.filename",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("quality-management-file-actions"),
        field: "file-actions",
        flex: 1,
        autoHeight: true,
        valueGetter: (params: ValueGetterParams) =>
          this.actionsValueGetter(params),
        cellRenderer: CellActionsComponent,
      },
    ];

    this.colDefsProductionSpectrum = [
      {
        headerName: this.headerTranslation("prod-spectrum-category"),
        field: "topic",
        cellRenderer: (params: any) => {
          if (params.value == "unassigned") {
            return this.translate.instant(
              "comp.title.button-to-inputs.unassigned-topic"
            );
          }

          return params.value;
        },
        flex: 1,
      },
      {
        headerName: this.headerTranslation("prod-spectrum-attribute"),
        field: "name",
        flex: 1,
        cellRenderer: (params: any) => {
          if (params.value && params.value.startsWith("custom-")) {
            return params.value.slice("custom-".length);
          }

          return params.value;
        },
      },
      {
        headerName: this.headerTranslation("prod-spectrum-parameter-name"),
        valueGetter: "data.parameter.nameDisplayValue",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("prod-spectrum-parameter-unit"),
        valueGetter: "data.parameter.unitDisplayValue",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("prod-spectrum-parameter-value"),
        valueGetter: "data.parameter.displayValue",
        flex: 1,
      },
    ];
  }

  // Row Data: The data to be displayed.
  rowData = [
    { supplier: "Tesla", lastAudit: "Model Y", actions: 64950 },
    { supplier: "Ford", lastAudit: "F-Series", actions: 33850 },
    { supplier: "Toyota", lastAudit: "Corolla", actions: 29600 },
  ];

  headerTranslation(translateKey: string) {
    if (translateKey == "old-value" && this.screenMode == "display-only") {
      translateKey = "value";
    }

    return this.translate.instant(
      `screen.admin.feedback-detail.table-columns.${translateKey}`
    );
  }

  fieldTranslation(formField: string, key: string) {
    return this.translate.instant(`screen.${formField}-labels.${key}`);
  }

  getCompareData(
    assessmentToCompare: any,
    assessmentToAudit: any,
    formField: string
  ) {
    let rowData: any[] = [];

    if (this.screenMode == "audit" && assessmentToAudit && assessmentToAudit["form_data"]) {
      let formDataToCompare: any = {};
      if (assessmentToCompare) {
        formDataToCompare = assessmentToCompare["form_data"][formField];
      }

      let formDataToAudit = assessmentToAudit["form_data"][formField];
      if (formDataToAudit) {
        let keys = Object.keys(formDataToAudit);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (formField === "general" && key === "name") {
            continue;
          }
          let valueToAudit = formDataToAudit[key];
          let valueToCompare = formDataToCompare ? formDataToCompare[key] : undefined;
          rowData.push({
            field: key,
            fieldTranslated: this.fieldTranslation(formField, key),
            oldValue: valueToCompare,
            newValue: valueToAudit,
          });
        }
      }
    }
    else if (this.screenMode == "display-only" && assessmentToCompare && assessmentToCompare["form_data"]) {
      let formDataToCompare = assessmentToCompare["form_data"][formField];
      if (formDataToCompare) {
        let keys = Object.keys(formDataToCompare);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          let valueToCompare = formDataToCompare[key];
          rowData.push({
            field: key,
            fieldTranslated: this.fieldTranslation(formField, key),
            oldValue: valueToCompare,
            newValue: null,
          });
        }
      }
    }

    return rowData;
  }

  getContactsData(assessment: any) {
    let rowData = [];
    if (assessment && assessment["form_data"]) {
      let contacts = assessment["form_data"]["contacts"];
      if (contacts) {
        for (let i = 0; i < contacts.length; i++) {
          let contact = contacts[i];
          let { persons, ...rest } = contact; 
          if (
            persons &&
            persons.length > 0
          ) {
            let persons = contact["persons"];
            for (let j = 0; j < persons.length; j++) {
              let person = persons[j];
              if (person["gender"] == "") {
                person["gender"] = "male";
              }
              rowData.push({
                ...rest,
                person,
              });
            }
          }
        }
      }
    }
    return rowData;
  }

  getQualityManagementData(assessment: any) {
    let rowData = [];
    if (assessment && assessment["form_data"]) {
      let certificates = assessment["form_data"]["certificates"];
      if (certificates) {
        for (let i = 0; i < certificates.length; i++) {
          let certificate = certificates[i];
          if (
            certificate &&
            certificate["files"] &&
            certificate["files"].length > 0
          ) {
            let files = certificate["files"];
            for (let j = 0; j < files.length; j++) {
              let file = files[j];
              rowData.push({
                validUntilDate: certificate["validUntilDate"],
                ...certificate,
                file,
                assessmentId: assessment["id"]
              });
            }
          }
        }
      }
    }
    return rowData;
  }

  getProductionSpectrumData(assessment: any) {
    let rowData = [];
    if (assessment && assessment["form_data"]) {
      let productionSpectrums = assessment["form_data"]["productionSpectrum"];
      if (productionSpectrums) {
        for (let i = 0; i < productionSpectrums.length; i++) {
          let productionSpectrum = productionSpectrums[i];
          if (
            productionSpectrum &&
            productionSpectrum["parameter"] &&
            productionSpectrum["parameter"].length > 0
          ) {
            let topic = productionSpectrum["topic"];
              let topicDisplayValue = topic;
              if (topic == "unassigned") {
                topicDisplayValue = this.translate.instant("comp.title.button-to-inputs.unassigned-topic");
              }
              let name = productionSpectrum["name"];
              let nameDisplayValue = name;
              if (name && name.startsWith("custom-")) {
                nameDisplayValue = name.slice("custom-".length);
              }

            let parameters = productionSpectrum["parameter"];
            for (let j = 0; j < parameters.length; j++) {
              let parameter = parameters[j];
              let parameterNameDisplayValue = parameter["name"];
              if (parameterNameDisplayValue && parameterNameDisplayValue.startsWith("custom-")) {
                parameterNameDisplayValue = parameterNameDisplayValue.slice("custom-".length);
              }
              parameter["nameDisplayValue"] = parameterNameDisplayValue;
              parameter["unitDisplayValue"] = parameter.unit;
              parameter["displayValue"] = parameter.value;
              if (parameter["unit"] == "checkbox") {
                parameter["unitDisplayValue"] = "";
                let checked = parameter.value.checked;
                if (checked === true) {
                  parameter["displayValue"] = this.translate.instant("general.yes");
                } else {
                  parameter["displayValue"] = this.translate.instant("general.no");
                }
              }
              rowData.push({
                topic,
                topicDisplayValue,
                name,
                nameDisplayValue,
                parameter,
              });
            }
          }
        }
      }
    }
    return rowData;
  }

  actionsValueGetter(params: ValueGetterParams) {
    let downloadBtnConfig: IButton = {
      btnType: ButtonType.SECONDARY,
      title:
        "screen.admin.feedback-detail.table-columns.quality-management-file-action-download",
      enableBackwardIcon: false,
      enableForwardIcon: false,
      iconType: ButtonIconType.DOWNLOAD,
      task: new DownloadCertificateTask(params.data, this.assessmentService, false),
    };

    let viewBtnConfig: IButton = {
      btnType: ButtonType.SECONDARY,
      title:
        "screen.admin.feedback-detail.table-columns.quality-management-file-action-view",
      enableBackwardIcon: false,
      enableForwardIcon: false,
      iconType: ButtonIconType.SHOW,
      task: new DownloadCertificateTask(params.data, this.assessmentService, true),
    };

    params.data["actions"] = [viewBtnConfig, downloadBtnConfig];
    return params;
  }
}
export class AuditAssessmentTask implements Task {
  constructor(
    private parent: AssessmentsDetailComponent,
    private finalize: boolean,
    private dialog: MatDialog,
    private translate: TranslateService,
    private saveComments: boolean,
    private assessmentService: AssessmentService
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    if (dialogRef) {
      dialogRef.close();
    }
    await this.saveAssessment(this.finalize, this.saveComments);
  }

  saveCompareData(assessment: any, sectionId: string, rowData: any[]) {
    let sectionData = assessment["form_data"][sectionId];
    for (let i = 0; i < rowData.length; i++) {
      let { field, newValue } = rowData[i];
      sectionData[field] = newValue;
    }
  }

  saveContacts(assessment: any, rowData: any[]) {
    let sectionData = assessment["form_data"]["contacts"] as any[];
    sectionData = [];
    for (let i = 0; i < rowData.length; i++) {
      let { ident, person, ...rest } = rowData[i];
      let contact = sectionData.find((d) => (d.ident = ident));
      if (!contact) {
        contact = {
          ident,
          persons: [],
          ...rest,
        };
        sectionData.push(contact);
      }
      contact["persons"].push(person);
    }
  }

  saveQualityManagement(assessment: any) {
    // nothing to modify
  }

  saveProductionSpectrum(assessment: any) {
    // nothing to modify
  }

  async saveAssessment(finalize: boolean, saveComments: boolean) {
    let assessmentToAudit = this.parent.assessments["assessment_to_audit"];
    if (assessmentToAudit) {
      let formData = assessmentToAudit["form_data"];
      if (!formData) {
        formData = {};
      }
      this.saveCompareData(
        assessmentToAudit,
        "general",
        this.parent.rowDataGeneral
      );
      this.saveCompareData(
        assessmentToAudit,
        "employees",
        this.parent.rowDataEmployees
      );
      this.saveContacts(assessmentToAudit, this.parent.rowDataContacts);
      this.saveProductionSpectrum(assessmentToAudit);

      let comments: string;
      if (saveComments) {
        comments = this.parent.assessmentComments;
      } else {
        comments = "";
      }

      if (finalize) {
        await this.assessmentService
          .finalizeAssessmentAudit(
            assessmentToAudit["id"],
            assessmentToAudit["form_data"],
            comments
          )
          .toPromise();
      } else {
        await this.assessmentService
          .auditAssessment(
            assessmentToAudit["id"],
            assessmentToAudit["form_data"],
            comments
          )
          .toPromise();
      }

      console.log("audited assessment", assessmentToAudit);
      this.parent.auditEvent.emit(assessmentToAudit);
    }
  }
}
export class AuditFinalizeAssessmentTask implements Task {
  private finalize: boolean = true;

  constructor(
    private parent: AssessmentsDetailComponent,
    private dialog: MatDialog,
    private translate: TranslateService,
    private assessmentService: AssessmentService
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    let saveCommentsTask = new AuditAssessmentTask(
      this.parent,
      this.finalize,
      this.dialog,
      this.translate,
      true,
      this.assessmentService
    );
    let removeCommentsTask = new AuditAssessmentTask(
      this.parent,
      this.finalize,
      this.dialog,
      this.translate,
      false,
      this.assessmentService
    );
    let removeCommentsButton = ModalButtonGenerator.createButton(
      "general.yes",
      removeCommentsTask,
      ButtonType.PRIMARY
    );
    let saveCommentsButton = ModalButtonGenerator.createButton(
      "general.no",
      saveCommentsTask,
      ButtonType.PRIMARY
    );

    this.dialog.open(ModalComponent, {
      data: {
        message: this.translate.instant("screen.admin.feedback-detail.save-comments-modal"),
        buttons: [saveCommentsButton, removeCommentsButton],
      },
    });
  }
}
export class DownloadCertificateTask implements Task {
  constructor(
    private certificate: any,
    private assessmentService: AssessmentService,
    private showOnly: boolean,
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    console.log("show certificate", this.certificate);
    let data = await this.assessmentService
      .getCertificateFilesByAssessment(
        this.certificate["assessmentId"],
        this.certificate["ident"],
        this.certificate["file"]["filename"]
      )
      .toPromise();
    let file = new Blob([data], {
      type: "application/pdf",
    });
    //trick to download store a file having its URL
    let fileURL = URL.createObjectURL(file);
    let a = document.createElement("a");
    a.href = fileURL;
    a.target = "_blank";
    if (!this.showOnly) {
      a.download = `${this.certificate["file"]["filename"]}`;
    }
    document.body.appendChild(a);
    a.click();
  }
}