<div class="css-input-container">
  <span *ngIf="label && label.length > 0" class="css-input-label">
    {{ label | translate }}{{ getUnit() }}{{ required ? "*" : "" }}</span
  >
  <input
    class="css-input-item"
    [type]="type"
    [name]="label"
    [id]="label"
    [value]="value ? value : null"
    [min]="minValue"
    [max]="maxValue"
    [disabled]="disabled"
    (keyup)="valueChanged($event)"
    (change)="valueChanged($event)"
    [ngClass]="{
      'field-required': required === true && (!value || value.length == 0)
    }"
    [placeholder]="placeholder | translate"
  />
  <!--
  <span *ngIf="showRequiredMarker()" class="field-required">
    {{ "general.field-required" | translate }}
  </span>
  -->
</div>
