import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const AUTH_API = environment.API_ENDPOINT + "/assessment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class AssessmentService {
  constructor(private http: HttpClient) {}

  create(formVersion: string, formData: any): Observable<any> {
    return this.http.post(
      AUTH_API,
      {
        form_version: formVersion,
        form_data: formData,
      },
      httpOptions
    );
  }

  updateSheet(sheetName: string, sheetData: any): Observable<any> {
    return this.http.post(
      AUTH_API + "/sheet",
      {
        sheetName,
        sheetData,
      },
      httpOptions
    );
  }

  finalizeAssessment(): Observable<any> {
    return this.http.post(AUTH_API + "/finalize", {}, httpOptions);
  }

  updateCertificate(certificate: any) {
    return this.http.post(
      AUTH_API + "/certificate",
      {
        certificate,
      },
      httpOptions
    );
  }

  find(): Observable<any> {
    return this.http.get(AUTH_API);
  }

  getLastFinalizationTimestamp(): Observable<any> {
    return this.http.get(AUTH_API + "/finalize");
  }

  getAssessmentsForAudit(): Observable<any> {
    return this.http.get(AUTH_API + "/foraudit");
  }

  getAuditedAssessments(): Observable<any> {
    return this.http.get(AUTH_API + "/audited");
  }

  getLastAuditedAssessmentByCompanyId(companyId: string): Observable<any> {
    return this.http.get(AUTH_API + "/audited/company/" + companyId);
  }

  getAssessmentsForAuditComparison(assessmentId: string): Observable<any> {
    return this.http.get(AUTH_API + "/foraudit/" + assessmentId);
  }

  getCertificateFilesByAssessment(
    assessmentId: string,
    certificateIdent: string,
    fileIdent: string
  ): Observable<any> {
    return this.http.get(
      AUTH_API +
        `/certificate/${assessmentId}/${certificateIdent}/${fileIdent}`,
      {
        headers: {
          "Content-type": "application/pdf",
        },
        responseType: "arraybuffer",
      }
    );
  }

  auditAssessment(
    assessmentId: string,
    assessmentData: any,
    auditComments: string
  ) {
    return this.http.post(
      AUTH_API + "/audit/" + assessmentId,
      {
        form_data: assessmentData,
        audit_comments: auditComments,
      },
      httpOptions
    );
  }

  finalizeAssessmentAudit(
    assessmentId: string,
    assessmentData: any,
    auditComments: string
  ) {
    return this.http.post(
      AUTH_API + "/audit/finalize/" + assessmentId,
      {
        form_data: assessmentData,
        audit_comments: auditComments,
      },
      httpOptions
    );
  }

  setEAPKey(assessmentId: string, eapId: string) {
    return this.http.patch(
      `${AUTH_API}/${assessmentId}/eapKey`,
      {
        eapKey: eapId,
      },
      httpOptions
    );
  }

  getEAPKey(assessmentId: string) {
    return this.http.get(`${AUTH_API}/${assessmentId}/eapkey`);
  }
}
