import { Component, Input } from "@angular/core";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: "app-square-button",
  templateUrl: "./square-button.component.html",
  styleUrls: ["./square-button.component.css"],
})
export class SquareButtonComponent extends ButtonComponent {
  btnType = ButtonType.SECONDARY;

  @Input()
  disabled = false;

  @Input()
  description: string = "";

  constructor() {
    super();
  }

  executeTask(): Promise<void> {
    if (this.disabled) {
      return Promise.resolve();
    }
    return super.executeTask();
  }

  getBtnClass(): string {
    let clazzes = super.getBtnClass();
    if (this.disabled) {
      clazzes += " transparent";
      return clazzes;
    } else {
      return clazzes
        .split(" ")
        .filter((v) => v !== "transparent")
        .join(" ");
    }
  }
}
