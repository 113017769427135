<div class="overview-header">
  <div class="css-ui-line"></div>
  <div class="overview-header-content">
    <div class="overview-header-img">
      <img src="assets/img/logos/customer-logo.png" alt="customer logo" />
    </div>
    <div class="overview-header-title">
      <h2>{{ "screen.admin.supplier-portal" | translate }}</h2>
      <h4 style="font-weight: 400">
        {{ "screen.admin.supplier-portal-1" | translate }}
      </h4>
    </div>
  </div>
</div>
<div class="supplier-overview-body">
  <router-outlet></router-outlet>
</div>
