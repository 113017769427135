<h2>Vielen Dank für Ihre Kooperation!</h2>

<p>
  Bitte überprüfen Sie sorgfältig, ob die von Ihnen eingetragenen Daten
  vollständig und aktuell sind. Falls Änderungen erforderlich sind,
  aktualisieren Sie bitte die entsprechenden Felder. Wenn Ihre Daten korrekt und
  vollständig sind und Sie damit einverstanden sind, dass diese an die Firma
  Dirkra übermittelt werden, kreuzen Sie das untenstehende Kästchen an. <br />
</p>

<app-css-checkbox
  [value]="confirmation"
  (valueChange)="confirmationChanged($event)"
  style="display: flex"
></app-css-checkbox>

<app-button
  style="display: block"
  [cssStyle]="finalizeBtnConfig.cssStyle"
  [title]="finalizeBtnConfig.title"
  [iconType]="finalizeBtnConfig.iconType"
  (click)="finalizeAssessment()"
></app-button>
