import { KeyValue } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import { Logger } from "src/app/logger";
import { Task } from "src/app/tasks/task";
import { ICategoryTitleService } from "./category-title-service.interface";
import { RemoveCategoryTask } from "./tasks/remove-category.task";

@Component({
  selector: "app-button-to-inputs",
  templateUrl: "./button-to-inputs.component.html",
  styleUrls: ["./button-to-inputs.component.css"],
  host: {
    class: "master-detail-btn-to-inputs-container",
  },
})
export class ButtonToInputsComponent implements OnInit {
  logger = new Logger("ButtonToInputsComponent");

  currentSelectedCategory: any = {
    name: null,
    parameter: [],
  };
  addCategorySelectedOption?: string;
  addCategoryDetailSelectedOption?: any;

  removeCategoryIconType = ButtonIconType.REMOVE;
  removeCategoryDetailButtonType = ButtonType.SECONDARY;
  addBtnConfig = {
    title: "comp.title.add",
    btnType: ButtonType.SECONDARY,
    iconType: ButtonIconType.ADD,
  };

  @Input()
  enableRemoveCategory: boolean = false;

  @Input()
  enableAddCategory: boolean = false;

  @Input()
  enableAddIndividualCategory: boolean = false;

  enableAddIndividualCategoryParameter: boolean = false;

  @Input()
  enableRemoveCategoryDetail: boolean = false;

  @Input()
  enableAddCategoryDetail: boolean = false;

  private _categories: any[] = [];

  // @Input()
  // categories?: any[] = [];

  @Input()
  set categories(val: any[]) {
    this._categories = val;
    this.initTopics(this.topics, this._categories);
  }

  get categories(): any[] {
    return this._categories;
  }

  topics: any[] = [];
  selectableTopics: any[] = [];

  @Output()
  categoriesChange = new EventEmitter<any[]>();

  @Input()
  categoryTitleService?: ICategoryTitleService;

  private _selectableCategories: any[] = [];

  @Input()
  set selectableCategories(val: any[]) {
    this._selectableCategories = val;
    this.initTopics(this.selectableTopics, this._selectableCategories);
  }

  get selectableCategories() {
    return this._selectableCategories;
  }

  currentSelectableCategory: any;

  @Input()
  standardCategoryDetails: any[] = [];

  availableDetailCategories: any[] = [];

  removeCategoryTask!: Task; // TODO remove?

  addIndividualCategoryValue: string = "";
  addIndividualCategoryParameterValue: any = {
    name: null,
    unit: null,
  };

  constructor(private translateService: TranslateService) {
    this.removeCategoryTask = new RemoveCategoryTask(this);
  }

  categoryOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  categoryDetailOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  getTitle(name: string) {
    if (name && name.length > 0) {
      if (name.toLocaleLowerCase().startsWith("custom-")) {
        return this.translateService.instant(name.substring("custom-".length));
      }

      return this.translateService.instant(name);
    }

    return null;
  }

  getCategoryBtnClass(category: string) {
    if (category === this.currentSelectedCategory.name) {
      return "css-btn-primary-focus";
    }

    return "";
  }

  selectCategory(category: any) {
    if (!this.categories) {
      this.categories = [];
    }

    if (this.selectableCategories && this.selectableCategories.length > 0) {
      this.currentSelectableCategory = this.selectableCategories.find(
        (selectableCategory) => selectableCategory.name === category.name
      );
      if (!this.currentSelectableCategory && category.name.startsWith("custom-")) {
        this.currentSelectableCategory = JSON.parse(JSON.stringify(category));
      }
      if (
        this.currentSelectableCategory &&
        this.currentSelectableCategory["parameter"] &&
        this.currentSelectableCategory["parameter"].length > 0
      ) {
        this.addCategoryDetailSelectedOption =
          this.currentSelectableCategory["parameter"][0];
      }
    }

    if (this.currentSelectedCategory.name === category.name) {
      this.resetCurrentSelection();
      return;
    }

    this.currentSelectedCategory = category;

    this.resetCategoryParameterSelection();

    // set mandatory fields depending on defined selectable parameters
    if (
      this.currentSelectableCategory &&
      this.currentSelectableCategory.parameter &&
      this.currentSelectableCategory.parameter.length > 0
    ) {
      this.currentSelectableCategory.parameter.forEach(
        (selectableParameter: any) => {
          if (
            this.currentSelectedCategory &&
            this.currentSelectedCategory.parameter &&
            this.currentSelectedCategory.parameter.length > 0
          ) {
            let parameter = this.currentSelectedCategory.parameter.find(
              (p: any) => p.name === selectableParameter.name
            );
            if (parameter) {
              parameter.mandatory = selectableParameter.mandatory;
            }
          }
        }
      );
    }
  }

  getSelectableDetailCategories(selectableDetailCategories: any[]) {
    let detailCategories: any[] = this.currentSelectedCategory["parameter"];
    if (
      selectableDetailCategories &&
      selectableDetailCategories.length > 0 &&
      detailCategories &&
      detailCategories.length > 0
    ) {
      let newSelectableDetailCategories: any[] = [];
      selectableDetailCategories.forEach((selectableDetailCategory) => {
        let index = detailCategories.findIndex(
          (detailCategory) =>
            detailCategory.name === selectableDetailCategory.name
        );
        if (index == -1) {
          newSelectableDetailCategories.push(selectableDetailCategory);
        }
      });
      return newSelectableDetailCategories;
    }

    return selectableDetailCategories;
  }

  addCategory() {
    if (this.addCategorySelectedOption) {
      let index = this.categories.findIndex(
        (val) => val.name == this.addCategorySelectedOption
      );
      if (index === undefined || index == -1) {
        if (!this.categories) {
          this.categories = [];
        }
        let selectableCategory = this.selectableCategories.find(
          (v) => v.name === this.addCategorySelectedOption
        );
        let newCategory = this.createCategoryItem(
          selectableCategory.name,
          selectableCategory.label,
          selectableCategory.topic,
          selectableCategory["parameter"]
        );
        this.addTopic(this.topics, newCategory.topic);
        this.categories.push(newCategory);
        this.categories = [...this.categories];
        this.categoriesChange.emit(this.categories);
        this.selectCategory(newCategory);
      }
    }
  }

  private addTopic(topics: any[], topic: string) {
    if (topics.length == 0 || topics.findIndex((t) => t.name == topic) == -1) {
      topics.push(this.getTopic(topic));
    }
  }

  private removeTopic(topic: string, categories: any[]) {
    if (this.topics && this.topics.length > 0) {
      let topicIndex = this.topics.findIndex((t) => t.name == topic);
      if (topicIndex > -1) {
        if (
          !categories ||
          categories.length == 0 ||
          categories.findIndex((c) => c.topic == topic) == -1
        ) {
          this.topics.splice(topicIndex, 1);
        }
      }
    }
  }

  private initTopics(topics: any[], categories: any[]) {
    if (categories && categories.length > 0) {
      categories.forEach((c) => {
        if (!c.topic || c.topic.length == 0) {
          c.topic = "unassigned";
        }
        this.addTopic(topics, c.topic);
      });
    }
  }

  onAddCategorySelectionChanged() {
    if (this.addCategorySelectedOption === "customCategory") {
      this.enableAddIndividualCategory = true;
    } else {
      this.enableAddIndividualCategory = false;
    }
  }

  onAddCategoryParamterSelectionChanged() {
    if (this.addCategoryDetailSelectedOption === "customCategoryParameter") {
      this.enableAddIndividualCategoryParameter = true;
    } else {
      this.enableAddIndividualCategoryParameter = false;
    }
  }

  addIndividualCategory() {
    if (
      !this.addIndividualCategoryValue ||
      this.addIndividualCategoryValue.length == 0
    ) {
      return;
    }

    if (!this.categories) {
      this.categories = [];
    }

    let newCategory = this.createCategoryItem(
      `custom-${this.addIndividualCategoryValue}`,
      this.addIndividualCategoryValue,
      "unassigned"
    );

    this.addTopic(this.topics, newCategory.topic);

    this.categories.push(newCategory);
    this.categories = [...this.categories];
    this.categoriesChange.emit(this.categories);
    this.selectableCategories.push(newCategory);
    this.addIndividualCategoryValue = "";

    this.selectCategory(newCategory);
  }

  addIndividualCategoryParameter() {
    let { name, unit } = this.addIndividualCategoryParameterValue;
    if (name && name.length > 0) {
      let item = this.createCategoryParamterItem(name, unit);
      this.currentSelectedCategory["parameter"].push(item);
    }
  }

  resetCategoryParameterSelection() {
    this.addCategoryDetailSelectedOption = undefined;
    this.onAddCategoryParamterSelectionChanged();
  }

  addCategoryDetail() {
    if (this.addCategoryDetailSelectedOption && this.currentSelectedCategory) {
      let parameter: any[] = this.currentSelectedCategory["parameter"];
      if (!parameter) {
        parameter = [];
      }
      let index = parameter.findIndex(
        (val) => val.name == this.addCategoryDetailSelectedOption.name
      );
      if (index == -1) {
        parameter.push(this.addCategoryDetailSelectedOption);
        this.resetCategoryParameterSelection();
      }
    }
  }

  removeCategory(categoryName: string) {
    if (this.categories && this.categories.length > 0 && categoryName) {
      if (categoryName == this.currentSelectedCategory?.name) {
        this.resetCurrentSelection();
      }
      let categoryIndex = this.categories.findIndex(
        (val: any) => val.name === categoryName
      );
      if (categoryIndex > -1) {
        let category = this.categories[categoryIndex];
        this.categories.splice(categoryIndex, 1);
        this.categories = [...this.categories];
        this.categoriesChange.emit(this.categories);
        this.removeTopic(category.topic, this.categories);
      }
    }
  }

  removeCategoryDetail(categoryDetail: string) {
    if (
      this.categories &&
      this.categories.length > 0 &&
      this.currentSelectedCategory &&
      categoryDetail
    ) {
      let parameter: any[] = this.currentSelectedCategory["parameter"];
      if (parameter && parameter.length > 0) {
        let indexDetail = parameter.findIndex(
          (val: any) => val.name === categoryDetail
        );
        if (indexDetail > -1) {
          parameter.splice(indexDetail, 1);
          if (parameter.length == 0) {
            this.removeCategory(this.currentSelectedCategory.name);
          }
        }
      }
    }
  }

  resetCurrentSelection() {
    this.currentSelectedCategory = {
      name: null,
      parameter: [],
    };
  }

  findPermissibleCategory(categoryName: string) {
    return this.selectableCategories?.find(
      (val: any) => val.name === categoryName
    );
  }

  createCategoryItem(
    name: string,
    label: string,
    topic: string,
    parameter?: any[]
  ) {
    if (!label) {
      label = name;
    }

    if (!parameter) {
      parameter = [];
    } else if (parameter.length > 0) {
      parameter = parameter.filter((p) => p.mandatory === true);
      parameter.forEach((p) => {
        if (p.unit === "checkbox" && p.value) {
          p.value.checked = true;
        }
      });
    }

    if (!topic) {
      topic = "unassigned";
    }

    return {
      name: name,
      label: label,
      parameter: parameter,
      topic: topic,
    };
  }

  createCategoryParamterItem(name: string, unit: string) {
    return {
      label: `custom-${name}`,
      name: `custom-${name}`,
      unit: unit,
      value: null,
      mandatory: false,
    };
  }

  getTopic(name: string): any {
    let label = name;
    if (!name || name === "unassigned") {
      label = "comp.title.button-to-inputs.unassigned-topic";
    }

    return {
      name: name,
      label: label,
    };
  }

  ngOnInit(): void {}
}
