import { formatDate } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ColDef, ValueGetterParams } from "ag-grid-community"; // Column Definition Type Interface
import { CellActionsComponent } from "src/app/components/buttons/ag-grid/cell-actions/cell-actions.component";
import {
  ButtonComponent,
  IButton,
} from "src/app/components/buttons/button/button.component";
import { OPENFEEDBACKS } from "src/app/controller/constants/supplier-admin";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ModalComponent } from "src/app/modal/modal.component";
import { AssessmentService } from "src/app/services/api/assessment.service";
import { Task } from "src/app/tasks/task";

@Component({
  selector: "app-assessments-feedbacks",
  templateUrl: "./open-assessments.component.html",
  styleUrls: ["./open-assessments.component.css"],
})
export class OpenAssessmentsComponent implements OnInit {
  private _selectedAssessment: any | null = null;

  assessmentForComparison: any = {};

  backButtonLabel = "screen.admin.dashboard";

  set selectedAssessment(val: any | null) {
    this._selectedAssessment = val;
    if (val == null) {
      this.assessmentForComparison = {};
      this.title = "screen.admin.overview.open-feedbacks";
      this.description = "screen.admin.overview.open-feedbacks-description";
      this.backButtonLabel = "screen.admin.dashboard";
    } else {
      this.title = val["company_name"];
      this.description = "screen.admin.feedback-detail.check-description";
      this.backButtonLabel = "screen.admin.overview.open-feedbacks";
      this.loadAssessmentsForComparison(val);
    }
  }

  get selectedAssessment(): any | null {
    return this._selectedAssessment;
  }

  navigateBackTask: Task;

  ID: string = OPENFEEDBACKS.ID;
  PATH: string = OPENFEEDBACKS.PATH;
  title: string = OPENFEEDBACKS.TITLE;
  description: string = OPENFEEDBACKS.DESCRIPTION;
  comp: string = OpenAssessmentsComponent.name;

  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private assessmentService: AssessmentService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.navigateBackTask = new OpenFeedbacksNavigateBackTask(this, router, assessmentService);
    assessmentService.getAssessmentsForAudit().subscribe((d) => {
      if (d && d.length > 0) {
        this.rowData = d;
      }
    });
  }

  ngOnInit(): void {
    this.colDefs = [
      {
        headerName: this.headerTranslation("supplier"),
        field: "company_name",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("last-audit"),
        field: "finalization_timestamp",
        cellRenderer: (params: any) => {
          return this.formatDateCell(params.value);
        },
        flex: 1,
      },
      {
        headerName: this.headerTranslation("actions"),
        field: "actions",
        flex: 1,
        autoHeight: true,
        valueGetter: (params: ValueGetterParams) =>
          this.actionsValueGetter(params),
        cellRenderer: CellActionsComponent,
      },
    ];
  }

  // Row Data: The data to be displayed.
  rowData = [];

  loadAssessmentsForComparison(assessment: any) {
    this.assessmentService
      .getAssessmentsForAuditComparison(assessment["assessment_id"])
      .subscribe((d) => {
        this.assessmentForComparison = d;
      });
  }

  formatDateCell(dateString: string): string {
    if (!dateString) return ""; // handle null, undefined, etc.
    return formatDate(dateString, "dd.MM.yyyy HH:mm", this.locale);
  }

  headerTranslation(translateKey: string) {
    return this.translate.instant(
      `screen.admin.open-feedbacks.table-columns.${translateKey}`
    );
  }

  actionsValueGetter(params: ValueGetterParams) {
    let auditBtnConfig: IButton = {
      btnType: ButtonType.SECONDARY,
      title: "Prüfung",
      enableBackwardIcon: false,
      enableForwardIcon: false,
      iconType: ButtonIconType.EDIT,
      task: new AuditActionTask(this, params.data, this.router),
    };

    params.data["actions"] = [auditBtnConfig];
    return params;
  }

  async onAuditEvent(assessment: any) {
    await this.navigateBackTask.execute();
  }
}
export class AuditActionTask implements Task {
  constructor(
    private view: OpenAssessmentsComponent,
    private feedback: any,
    private router: Router
  ) {}

  execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    console.log("selected assessment", this.feedback);
    this.view.selectedAssessment = this.feedback;
    return Promise.resolve();
  }
}
export class OpenFeedbacksNavigateBackTask implements Task {
  constructor(
    private parent: OpenAssessmentsComponent,
    private router: Router,
    private assessmentService: AssessmentService
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    if (this.parent.selectedAssessment) {
      this.parent.selectedAssessment = null;
      this.parent.rowData = [];
      this.assessmentService.getAssessmentsForAudit().subscribe((d) => {
        if (d && d.length > 0) {
          this.parent.rowData = d;
        }
      });
    } else {
      this.router.navigate(["admin"]);
    }
  }
}
