import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonType } from 'src/app/enums/button-type.enum';
import { SaveUserPWTask } from 'src/app/screens/admin/user-management/user-management.component';
import { Task } from '../../../tasks/task';
import { ModalButton } from '../../buttons/modal-button';
import { ModalComponent } from '../../modal.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  title: string = ''; // not in use
  message: string = '';
  buttons: ModalButton[] = [];
  SECONDARY_BTN_REF = ButtonType.SECONDARY;
  taskLoading: boolean = false;
  password: string = "";

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any
  ) {
    if (data) {
      if (data.customMessage && data.customMessage == true){
        this.message = data.message
      }
      else {
        this.message = `<p class="modal-dialog-msg">${data.message}<p>`;
      }
      if (data.buttons) {
        this.buttons = data.buttons;
      }
    }
  }

  executeTask(task: Task) {
    if (task instanceof SaveUserPWTask) {
      task.password = this.password;
    }
    task.execute(this.dialogRef);
  }

  ngOnInit(): void {
    console.log('openend dialog');
  }

  enableTaskLoading(loading: boolean) {
    this.taskLoading = loading;
  }
}
