import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { JwtTokenService } from "src/app/auth/service/jwt-token.service";
import { UserService } from "src/app/services/api/user.service";

@Component({
  selector: "app-supplier-register",
  templateUrl: "./supplier-register.component.html",
  styleUrls: ["./supplier-register.component.css"],
})
export class SupplierRegisterComponent implements OnInit {
  form: any = {
    username: null,
    password: null,
  };

  isregisterFailed = false;
  errorMessage = "";
  loading = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private jwtTokenService: JwtTokenService
  ) {}

  ngOnInit(): void {
    if (this.jwtTokenService.getUser()) {
      if (!this.jwtTokenService.isTokenExpired()) {
        this.router.navigate(["supplier"]);
      }
    }
  }

  async onSubmit(): Promise<void> {
    const { companyname, email } = this.form;

    this.loading = true;
    try {
      console.log('test')
      await this.userService.register(companyname, email).toPromise();
      await this.router.navigate(["register-end"]);
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  reloadPage(): void {
    window.location.reload();
  }
}
