import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponent, IButton } from '../button/button.component';

export interface IBackButton extends IButton {
  route: any[];
}

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css'],
})
export class BackButtonComponent extends ButtonComponent implements OnInit, IBackButton {
  @Input()
  route: any[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
