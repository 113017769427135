import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ModalButtonGenerator } from "../modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "../modal/modal.component";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.error.code) {
          err.error.message = this.translate.instant(
            `error-messages.${err.error.code}`,
            err.error.messages
          );
        } else {
          err.error.message = this.translate.instant(
            `error-messages.InternalError`,
            {}
          );
        }

        let message =
          '<i class="fas fa-exclamation-triangle"></i><br>' + err.error.message;
        const dialogRef = this.dialog.open(ModalComponent, {
          data: {
            message,
            buttons: [ModalButtonGenerator.createCancelButton()],
          },
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          console.log("The dialog was closed");
        });
        return throwError(err);
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];
