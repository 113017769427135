<button
  class="btn css-btn"
  [ngClass]="getBtnClass()"
  (click)="executeTask()"
  tabindex="1"
  [disabled]="taskLoading"
  [style]="cssStyle"
>
  <div class="css-square-btn-icon" [ngClass]="{ hide: disabled }">
    <i class="{{ iconType }}"></i>
  </div>
  <div class="css-square-btn-main-container">
    <div *ngIf="enableBackwardIcon" class="css-clip-path-icon-container mr-2">
      <div class="css-clip-path-icon css-backward-icon"></div>
    </div>
    <div class="css-btn-title-container">
      <span *ngIf="title && title.length > 0" class="css-btn-title">{{
        title | translate
      }}</span>
    </div>
    <div *ngIf="enableForwardIcon" class="css-clip-path-icon-container">
      <div class="css-clip-path-icon css-forward-icon"></div>
    </div>
  </div>
  <div class="css-quare-btn-main-container">
    <p>{{ description | translate }}</p>
  </div>
</button>
