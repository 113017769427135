import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IButton } from "src/app/components/buttons/button/button.component";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { QualityManagementComponent } from "src/app/screens/supplier-form/quality-management/quality-management.component";
import { ButtonType } from "../../enums/button-type.enum";
import { Task } from "../../tasks/task";
import { ModalButton } from "../buttons/modal-button";
import { ModalComponent } from "../modal.component";

export enum CertificatesModalType {
  ADD = "add",
  EDIT = "edit",
}

export interface ModalComponentData {
  message: string;
  source: QualityManagementComponent;
  buttons: ModalButton[];
  certificate: any;
  index: number;
  dialogType: string;
}

@Component({
  selector: "app-modal",
  templateUrl: "./certificates-modal.component.html",
  styleUrls: ["./certificates-modal.component.css"],
})
export class CertificatesModalComponent implements OnInit {
  title: string = ""; // not in use
  message: string = "";
  buttons: ModalButton[] = [];
  SECONDARY_BTN_REF = ButtonType.SECONDARY;
  taskLoading: boolean = false;
  certificate: any;
  source!: QualityManagementComponent;
  validUntilDate: string | null = null;
  missingRequiredFields = false;
  index = 0;
  dialogType: string | null = null;

  yearRegex = /\d{4}/;
  monthRegex = /^(0?[1-9]|1[0-2])$/;

  files: any[] = [];

  removeFileBtnConfig: IButton = {
    btnType: ButtonType.SECONDARY,
    enableBackwardIcon: false,
    enableForwardIcon: false,
    title: "",
    iconType: ButtonIconType.REMOVE,
  };

  downloadFileBtnConfig: IButton = {
    btnType: ButtonType.SECONDARY,
    enableBackwardIcon: false,
    enableForwardIcon: false,
    title: "",
    iconType: ButtonIconType.DOWNLOAD,
  };

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ModalComponentData
  ) {
    if (data) {
      if (data.buttons) {
        this.buttons = data.buttons;
      }
      this.certificate = JSON.parse(JSON.stringify(data.certificate));
      this.validUntilDate = this.certificate.validUntilDate
        ? this.certificate.validUntilDate
        : null;
      this.title = this.certificate.label;
      this.source = data.source;
      this.index = data.index;
      this.dialogType = data.dialogType;
      if (this.certificate.files && this.certificate.files.length > 0) {
        this.files = this.certificate.files;
      }
    }
  }

  async onUploadFinished(uploadedFile: any) {
    let duplicate = this.certificate.files.find((v: any) => {
      return v.path === uploadedFile.path;
    });
    if (duplicate) {
      return;
    }

    let f: any = {
      filename: uploadedFile.filename,
      path: uploadedFile.path,
    };
    this.certificate.files.push(f);
    //await this.source.updateCertificate(this.certificate);
    f["lastModifiedDate"] = new Date();
  }

  async removeFile(file: any) {
    let index = this.certificate.files.indexOf(file);
    this.certificate.files.splice(index, 1);
    // await this.source.updateCertificate(this.certificate);
  }

  async saveCertificate() {
    let dateRegex = /^([1-9]|[0][1-9]|[1][0-2])[.]([2][0-9][2-9][0-9])$/;
    if (
      !this.certificate.files ||
      this.certificate.files.length == 0 ||
      !this.validUntilDate ||
      !this.validUntilDate.match(dateRegex) ||
      (this.dialogType === CertificatesModalType.ADD &&
        (!this.certificate.name || this.certificate.name.length == 0))
    ) {
      this.missingRequiredFields = true;
      return;
    }

    this.certificate.validUntilDate = this.validUntilDate;

    if (this.dialogType === CertificatesModalType.ADD) {
      this.certificate.ident = `custom-${this.certificate.name}`.trim();
    }

    await this.source.updateCertificate(this.certificate, this.index);
    this.dialogRef.close();
  }

  validUntilDateChanged(val: string, part: string) {
    let validUntilDate = this.validUntilDate;
    switch (part) {
      case "MM":
        if (val.match(this.monthRegex)) {
          if (validUntilDate) {
            let year = validUntilDate.split(".")[1];
            validUntilDate = `${("0" + val).slice(-2)}.${year}`;
          } else {
            validUntilDate = `${("0" + val).slice(-2)}.`;
          }
        }

        break;

      case "YYYY":
        if (val.match(this.yearRegex)) {
          if (validUntilDate) {
            let month = validUntilDate.split(".")[0];
            validUntilDate = `${month}.${val}`;
          } else {
            validUntilDate = `.${val}`;
          }
        }
        break;
      default:
        break;
    }
    this.validUntilDate = validUntilDate;
  }

  executeTask(task: Task) {
    this.enableTaskLoading(true);
    task.execute(this.dialogRef);
  }

  ngOnInit(): void {
    console.log("openend dialog");
  }

  enableTaskLoading(loading: boolean) {
    this.taskLoading = loading;
  }
}
