import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "src/app/auth/service/auth.service";
import { JwtTokenService } from "src/app/auth/service/jwt-token.service";
import { TokenStorageService } from "../../services/token-storage.service";
import { UserRoles } from "src/app/enums/user-roles.enum";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null,
  };
  isLoginFailed = false;
  errorMessage = "";
  loading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private jwtTokenService: JwtTokenService
  ) {}

  ngOnInit(): void {
    if (this.jwtTokenService.getUser()) {
      if (!this.jwtTokenService.isTokenExpired()) {
        this.router.navigate(["supplier"]);
      }
    }
  }

  async onSubmit(): Promise<void> {
    const { username, password } = this.form;

    this.loading = true;
    try {
      await this.authService.login(username, password);
      let role = this.jwtTokenService.getRole();
      if (role === UserRoles.ADMIN || role === UserRoles.PURCHASING_OPERATOR) {
        await this.router.navigate(["admin"]);
      } else {
        await this.router.navigate(["supplier"]);
      }
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  reloadPage(): void {
    window.location.reload();
  }
}
