import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "src/environments/environment";
import { AuthService } from "./auth/service/auth.service";
import { JwtTokenService } from "./auth/service/jwt-token.service";
import { ButtonIconType } from "./enums/button-icon-type.enum";
import { ButtonType } from "./enums/button-type.enum";
import { AttributeManagerService } from "./services/attribute-manager.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = `${environment.CUSTOMER_NAME}-app`;
  currentUser!: any;

  logoutBtnConfig = {
    btnType: ButtonType.SECONDARY,
    title: "general.logout",
    iconType: ButtonIconType.LOGOUT,
  };

  // private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  hideTopbar!: boolean;
  username?: string;
  arbeitszeit?: string;
  name?: string;
  vorname?: string;
  month?: string;

  monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  showInstallationPrompt = false;
  deferredPrompt: any = null;
  displayMode = "browser";
  scrollPosY = 0;

  logoUrl: string = environment.CUSTOMER_LOGO_URL;

  @HostListener("window:scroll") onScroll(e: Event): void {
    this.addOnScrollShrinkListener();
  }

  @ViewChild("dynamicComponent", { read: ViewContainerRef }) myRef: any;

  constructor(
    private router: Router,
    private attributeManager: AttributeManagerService,
    translate: TranslateService,
    private authService: AuthService,
    private jwtTokenService: JwtTokenService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("de");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use("de");

    authService.setAppComponent(this);
  }

  ngOnInit(): void {
    this.setCurrentDate();

    this.isLoggedIn =
      this.jwtTokenService.jwtToken != undefined &&
      !this.jwtTokenService.isTokenExpired();

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // this.showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    window.addEventListener("appinstalled", () => {
      // Hide the app-provided install promotion
      this.hideInstallPromotion();
      // Clear the deferredPrompt so it can be garbage collected
      this.deferredPrompt = null;
      // Optionally, send analytics event to indicate successful install
      console.log("PWA was installed");
    });

    this.checkDisplayMode();
  }

  private setCurrentDate() {
    const currentDate = new Date();

    // Copy date so don't modify original
    var d = new Date(
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
    );
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["login"]);
    this.hideTopbar = false;
  }

  toggleSidebar() {
    this.hideTopbar = !this.hideTopbar;
    this.attributeManager.toggleUserSidebar();
  }

  route(route: String) {
    this.toggleSidebar();
    this.router.navigate([route]);
  }

  showInstallPromotion() {
    this.showInstallationPrompt = true;
  }

  hideInstallPromotion() {
    this.showInstallationPrompt = false;
  }

  async installPromotion() {
    // Hide the app provided install promotion
    this.hideInstallPromotion();
    // Show the install prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await this.deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    this.deferredPrompt = null;
  }

  checkDisplayMode() {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      this.displayMode = "standalone";
    }
    console.log("application runs in " + this.displayMode + " mode");
  }

  // loadComponent() {
  //   let componentFactory =
  //     this.componentFactoryResolver.resolveComponentFactory(ModalComponent);

  //   this.myRef.clear();
  //   let componentRef = this.myRef.createComponent(componentFactory);
  //   (<ModalComponent>componentRef.instance).title = 'Modal';
  //   (<ModalComponent>componentRef.instance).content = 'Test';
  //   (<ModalComponent>componentRef.instance).buttonGroup = [
  //     ButtonGroup.OK_BUTTON,
  //     ButtonGroup.CANCEL_BUTTON,
  //   ];

  //   componentRef.changeDetectorRef.detectChanges();
  // }

  addOnScrollShrinkListener() {
    let navbar = document.getElementById("navbarContainer");
    let bottombar = document.getElementById("bottombarContainer");
    let currentYPos = window.pageYOffset || document.documentElement.scrollTop;

    if (
      currentYPos > this.scrollPosY &&
      (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)
    ) {
      if (navbar != null && !navbar.classList.contains("shrink-top")) {
        navbar.classList.add("shrink-top");
      }
    } else {
      if (navbar != null && navbar.classList.contains("shrink-top")) {
        navbar.classList.remove("shrink-top");
      }
    }

    if (
      currentYPos > this.scrollPosY &&
      (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)
    ) {
      // downscroll code
      if (bottombar != null && !bottombar.classList.contains("shrink-bottom")) {
        bottombar.classList.add("shrink-bottom");
      }
    } else {
      // upscroll code
      if (bottombar != null && bottombar.classList.contains("shrink-bottom")) {
        bottombar.classList.remove("shrink-bottom");
      }
    }

    this.scrollPosY = currentYPos;
  }
}
