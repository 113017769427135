import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";
import { Logger } from "src/app/logger";

@Injectable({
  providedIn: "root",
})
export class JwtTokenService {
  logger = new Logger("JwtTokenService");

  jwtToken?: string;
  decodedToken?: { [key: string]: any };

  constructor() {}

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
      // this.logger.log("[decodeToken] decoded token", this.decodedToken);
    }
  }

  getDecodeToken() {
    return jwt_decode(this.jwtToken ? this.jwtToken : "");
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.user : null;
  }

  getRole(): string {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.user.role : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = Number(this.getExpiryTime());
    if (expiryTime) {
      return 1000 * expiryTime - new Date().getTime() < 5000;
    } else {
      return false;
    }
  }

  clearToken() {
    this.jwtToken = undefined;
    this.decodedToken = undefined;
  }
}
