import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";

@Component({
  selector: "app-split-pane",
  templateUrl: "./split-pane.component.html",
  styleUrls: ["./split-pane.component.css"],
})
export class SplitPaneComponent {

  @ViewChild('leftPane') leftPane!: ElementRef<HTMLDivElement>;
  @ViewChild('container') container!: ElementRef<HTMLDivElement>;
  dragging = false;
  private initialX: number = 0;
  private initialWidth: number = 0;

  constructor() { }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.dragging) return;
    const dx = event.clientX - this.initialX;
    const newWidth = this.initialWidth + dx;
    this.leftPane.nativeElement.style.width = `${newWidth}px`;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (this.dragging) {
      this.dragging = false;
    }
  }

  onDividerMouseDown(event: MouseEvent) {
    this.dragging = true;
    this.initialX = event.clientX;
    this.initialWidth = this.leftPane.nativeElement.offsetWidth;
    event.preventDefault(); // Prevent text selection and other side effects
  }
}
