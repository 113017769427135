<mat-dialog-content [innerHTML]="message"> </mat-dialog-content>
<mat-dialog-actions class="custom-mat-dialog-actions">
  <div class="modal-dialog-footer" *ngIf="!taskLoading">
    <app-button
      *ngFor="let button of buttons"
      [btnType]="button.type"
      [dialogRef]="dialogRef"
      [task]="button.task"
      [title]="button.text"
      [customClasses]="'css-modal-button'"
    ></app-button>
  </div>
  <div class="modal-dialog-footer" *ngIf="taskLoading">
    <button class="btn btn-custom btn-outline">
      <div class="spinner-border spinner-border-sm"></div>
    </button>
  </div>
</mat-dialog-actions>
