<div class="supplier-overview-header">
  <div class="css-ui-line"></div>
  <div class="supplier-overview-header-img">
    <img src="assets/img/logos/customer-logo.png" alt="customer logo" />
  </div>
</div>
<div class="supplier-overview-body">
  <div class="supplier-overview-container">
    <div class="supplier-overview-info">
      <span class="supplier-overview-info-title">{{
        "screen.supplierOverview" | translate
      }}</span>
      <span *ngFor="let info of supplierInfos">{{ info }}</span>
    </div>
    <div class="supplier-overview-controls">
      <app-button
        *ngFor="let btn of controls"
        [btnType]="btn.btnType"
        [enableBackwardIcon]="btn.enableBackwardIcon"
        [enableForwardIcon]="btn.enableForwardIcon"
        [title]="btn.title"
        [task]="btn.task"
      ></app-button>
    </div>
  </div>
</div>
<div *ngIf="finTimestamp" class="supplier-timestamp-info">
  {{"screen.lastFinalizationLabel" | translate}} {{ finTimestamp | date : "dd.MM.yyyy HH:mm" }}
</div>
<div class="supplier-overview-psuedo-grow"></div>
<div class="supplier-overview-footer">
  <div class="css-ui-line"></div>
</div>
