<app-toolbar
  [backButtonLabel]="backButtonLabel"
  [backButtonTask]="navigateBackTask"
  [title]="title"
  [description]="description"
></app-toolbar>
<div class="user-management-container">
  <h4>{{ 'screen.admin.user-management.add-user.title' | translate }}</h4>
  <p>{{ 'screen.admin.user-management.add-user.description' | translate }}</p>
  <div class="user-management-add-container">
    <app-css-text-input
      [type]="'text'"
      [placeholder]="'screen.admin.user-management.username'"
      [style]="'width: 100%;'"
      [(value)]="addUserForm.username"
    ></app-css-text-input>
    <app-css-text-input
      [type]="'password'"
      [placeholder]="'screen.admin.user-management.password'"
      [style]="'width: 100%;'"
      [(value)]="addUserForm.password"
    ></app-css-text-input>
    <app-button
      [title]="addBtnConfig.title"
      [iconType]="addBtnConfig.iconType"
      [task]="addBtnConfig.task"
    ></app-button>
  </div>
  <h4 style="margin-top: 1em;">{{ 'screen.admin.user-management.manage-users.title' | translate }}</h4>
  <p>{{ 'screen.admin.user-management.manage-users.description' | translate }}</p>
  <ag-grid-angular
    class="ag-theme-alpine"
    style="height: 500px"
    [rowData]="userRowData"
    [columnDefs]="userGridColDefs"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
