import { MatDialogRef } from "@angular/material/dialog";
import { ButtonComponent } from "src/app/components/buttons/button/button.component";
import { ModalComponent } from "src/app/modal/modal.component";
import { Task } from "src/app/tasks/task";
import { ButtonToInputsComponent } from "../button-to-inputs.component";

export class RemoveCategoryTask implements Task {
  constructor(private comp: ButtonToInputsComponent) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    if (this.comp.categories && this.comp.currentSelectedCategory) {
      delete this.comp.categories[this.comp.currentSelectedCategory];
    }
  }
}
