import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgGridModule } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './auth/interceptor/jwt.interceptor';
import { CellActionsComponent } from './components/buttons/ag-grid/cell-actions/cell-actions.component';
import { BackButtonComponent } from './components/buttons/back-button/back-button.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { NavigateButtonComponent } from './components/buttons/navigate-button/navigate-button.component';
import { SquareButtonComponent } from './components/buttons/square-button/square-button.component';
import { FooterPanelComponent } from './components/header-footer-panel/footer-panel/footer-panel.component';
import { HeaderPanelControlComponent } from './components/header-footer-panel/header-panel/header-panel-control/header-panel-control.component';
import { HeaderPanelInfoComponent } from './components/header-footer-panel/header-panel/header-panel-info/header-panel-info.component';
import { HeaderPanelComponent } from './components/header-footer-panel/header-panel/header-panel.component';
import { CssCheckboxComponent } from './components/input/css-checkbox/css-checkbox.component';
import { CssPhoneInputComponent } from './components/input/css-phone-input/css-phone-input.component';
import { CssRadioButtonComponent } from './components/input/css-radio-button/css-radio-button.component';
import { CssSelectComponent } from './components/input/css-select/css-select.component';
import { CssTextInputComponent } from './components/input/css-text-input/css-text-input.component';
import { UploadFileComponent } from './components/input/upload-file/upload-file.component';
import { ButtonToInputsComponent } from './components/master-detail/button-to-inputs/button-to-inputs.component';
import { TopicFilterPipe } from './components/master-detail/button-to-inputs/helper/topic-filter.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SplitPaneComponent } from './components/split-pane/split-pane.component';
import { PasswordMatchDirective } from './directives/password-match.directive';
import { ErrorInterceptor } from './helpers/auth.interceptor';
import { CertificatesModalComponent } from './modal/certificates-modal/certificates-modal.component';
import { PersonModalComponent } from './modal/contacts/person-modal/person-modal.component';
import { ModalComponent } from './modal/modal.component';
import { ResetPasswordComponent } from './modal/user-management/reset-password/reset-password.component';
import { FilterHiddenButtonsPipe } from './pipes/filter-hidden-buttons/filter-hidden-buttons.pipe';
import { AdminDashboardComponent } from './screens/admin/admin-dashboard/admin-dashboard.component';
import { AssessmentsDetailComponent } from './screens/admin/assessment-detail/assessment-detail.component';
import { OpenAssessmentsComponent } from './screens/admin/open-assessments/open-assessments.component';
import { OverviewComponent as AdminOverviewComponent } from './screens/admin/overview/overview.component';
import { SupplierAdminOverviewComponent } from './screens/admin/supplier-admin-overview/supplier-admin-overview.component';
import { ToolbarComponent } from './screens/admin/toolbar/toolbar.component';
import { UserManagementComponent } from './screens/admin/user-management/user-management.component';
import { FinalizationEndComponent } from './screens/finalization-end/finalization-end.component';
import { LoginComponent } from './screens/login/login.component';
import { OverviewComponent } from './screens/overview/overview.component';
import { RegistrationEndComponent } from './screens/registration-end/registration-end.component';
import { ContactsComponent } from './screens/supplier-form/contacts/contacts.component';
import { EmployeesComponent } from './screens/supplier-form/employees/employees.component';
import { FinalizationComponent } from './screens/supplier-form/finalization/finalization.component';
import { GeneralComponent } from './screens/supplier-form/general/general.component';
import { ProductionSpectreComponent } from './screens/supplier-form/production-spectre/production-spectre.component';
import { QualityManagementComponent } from './screens/supplier-form/quality-management/quality-management.component';
import { SupplierFormComponent } from './screens/supplier-form/supplier-form.component';
import { UtilizationRateProductionLinesComponent } from './screens/supplier-form/utilization-rate-production-lines/utilization-rate-production-lines.component';
import { SupplierOverviewComponent } from './screens/supplier-overview/supplier-overview.component';
import { SupplierRegisterComponent } from './screens/supplier-register/supplier-register.component';
import { SocketioService } from './services/socketio.service';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { CellButtonComponent } from './components/buttons/ag-grid/cell-button/cell-button.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translateService: TranslateService) {
    return () => new Promise<any>((resolve: any) => {
      translateService.use('de').subscribe(() => {
        console.log('Translations loaded');
        resolve();
      }, err => {
        console.error('Error with translation files:', err);
        resolve();
      });
    });
  }

@NgModule({
    declarations: [
        AppComponent,
        SplashScreenComponent,
        ModalComponent,
        PasswordMatchDirective,
        ButtonComponent,
        BackButtonComponent,
        NavigateButtonComponent,
        OverviewComponent,
        LoginComponent,
        HeaderPanelComponent,
        HeaderPanelInfoComponent,
        HeaderPanelControlComponent,
        ProgressBarComponent,
        FooterPanelComponent,
        CssTextInputComponent,
        SupplierFormComponent,
        GeneralComponent,
        ContactsComponent,
        EmployeesComponent,
        UtilizationRateProductionLinesComponent,
        QualityManagementComponent,
        ProductionSpectreComponent,
        SupplierOverviewComponent,
        AdminOverviewComponent,
        ButtonToInputsComponent,
        CssRadioButtonComponent,
        CssCheckboxComponent,
        SpinnerComponent,
        CssSelectComponent,
        CssPhoneInputComponent,
        UploadFileComponent,
        CertificatesModalComponent,
        FilterHiddenButtonsPipe,
        PersonModalComponent,
        FinalizationComponent,
        FinalizationEndComponent,
        TopicFilterPipe,
        SquareButtonComponent,
        OpenAssessmentsComponent,
        SupplierAdminOverviewComponent,
        CellActionsComponent,
        SplitPaneComponent,
        AssessmentsDetailComponent,
        AdminDashboardComponent,
        ToolbarComponent,
        SupplierRegisterComponent,
        RegistrationEndComponent,
        UserManagementComponent,
        ResetPasswordComponent,
        CellButtonComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MatDialogModule,
        HammerModule,
        AgGridModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true
        },
        SocketioService,
        [
            { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        ]
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

