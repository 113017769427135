<div class="css-control-section">
  <app-button
    [iconType]="customerLogoConfig.src"
    [customClasses]="'customer-logo-header-panel'"
  ></app-button>
  <div class="css-control-panel">
    <app-button
      *ngIf="backTask"
      [enableBackwardIcon]="true"
      [cssStyle]="'width: 60px;'"
      [task]="backTask"
    ></app-button>
    <app-button
      [iconType]="homeBtnConfig.icon"
      [title]="homeBtnConfig.title"
      [cssStyle]="'padding-left: 1em; padding-right: 1em;'"
      [task]="homeTask"
      style="width: 100%"
    ></app-button>
  </div>
  <span *ngIf="lastFinalization" class="last-finalization-info"
    >{{ "screen.lastFinalizationLabel" | translate }}:
    {{ lastFinalization | date : "dd.MM.yyyy HH:mm" }}</span
  >
</div>
