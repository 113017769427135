<div class="css-input-container">
  <span *ngIf="label && label.length > 0" class="css-input-label">
    {{ label | translate }}</span
  >
  <select
    [name]="name"
    [id]="name"
    class="css-input-item h-100"
    (change)="selectionChanged($event)"
  >
    <option
      *ngFor="let option of options"
      [value]="option.value"
      [selected]="value === option.value"
    >
      {{ option.label | translate }}
    </option>
  </select>
</div>
