import { HttpClient } from "@angular/common/http";
import { IFormComponent } from "src/app/interfaces/form-component.interface";
import { Logger } from "src/app/logger";

export abstract class AbstractFormComponent implements IFormComponent {
  private loggger = new Logger("AbstractFormComponent");

  loadingFields = true;
  requiredFields: string[] = [];

  constructor(protected http?: HttpClient) {
    if (http) {
      http
        .get("assets/json/supplier-form/fields.json")
        .subscribe((data: any) => {
          setTimeout(() => {
            this.fields = data[this.getSheetName()];
            this.loggger.log("loaded fields", this.fields);
            if (this.fields && this.fields.length > 0) {
              this.requiredFields = this.fields
                .filter((field) => field.required === true)
                .map((field) => field.name);
              this.loggger.log("required fields", this.requiredFields);
            }
            this.loadingFields = false;
          }, 400);
        });
    } else {
      this.loadingFields = false;
    }
  }

  getRequiredFormValues() {
    return this.getFormValues();
  }

  fields: any[] = [];

  abstract PATH: string;
  abstract ID: number;
  abstract TITLE: string;

  abstract getFormValues(): any;
  abstract setFormValues(formValues: any, availableFormValues?: any): void;
  abstract getSheetName(): string;
}
