import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs-compat";
import { environment } from "src/environments/environment";
import { supplierFormConfig } from "../controller/constants/forms-config";
import { IScreenConfig } from "../interfaces/screen-config.interface";
import { Logger } from "../logger";
import { AbstractFormComponent } from "../screens/supplier-form/abstract-form.component";
import { AssessmentService } from "./api/assessment.service";

@Injectable({
  providedIn: "root",
})
export class FormServiceService {
  private logger = new Logger("FormService");

  private currenFormSub: BehaviorSubject<any> = new BehaviorSubject(null);
  currentForm: Observable<AbstractFormComponent> =
    this.currenFormSub.asObservable();

  private nextFormSub: BehaviorSubject<any> = new BehaviorSubject(null);
  nextForm: Observable<IScreenConfig> = this.nextFormSub.asObservable();

  private lastFormSub: BehaviorSubject<any> = new BehaviorSubject(null);
  lastForm: Observable<IScreenConfig> = this.lastFormSub.asObservable();

  private formSavedSub: BehaviorSubject<any> = new BehaviorSubject(null);
  formSaved: Observable<any> = this.formSavedSub.asObservable();

  async updateCurrentForm(form: AbstractFormComponent) {
    await this.loadForm(form);
    this.currenFormSub.next(form);
    this.nextFormSub.next(this.findNextForm());
    this.lastFormSub.next(this.findLastForm());
  }

  private findNextForm() {
    let routeId = supplierFormConfig.find(
      (screen) => screen.id == this.currenFormSub.value.ID
    )?.nextScreen;

    if (routeId) {
      return supplierFormConfig.find((form) => form.id == routeId);
    }

    return null;
  }

  private findLastForm() {
    let routeId = supplierFormConfig.find(
      (screen) => screen.id == this.currenFormSub.value.ID
    )?.backRoute;

    if (routeId) {
      return supplierFormConfig.find((form) => form.id == routeId);
    }

    return null;
  }

  async loadForm(form: AbstractFormComponent) {
    let formValues: any = {};
    let availableFormValues;
    if (environment.useTestData) {
      ({ formValues, availableFormValues } = this.loadTestForm(
        form,
        formValues,
        availableFormValues
      ));
    } else {
      let assessment = await this.assessmentService.find().toPromise();
      this.logger.log("[loadForm] loaded assessment from server", assessment);
      if (assessment && assessment[form.getSheetName()]) {
        formValues = assessment[form.getSheetName()];
      }
    }
    console.log("[loadForm]", formValues);
    if (Object.keys(formValues) && Object.keys(formValues).length > 0) {
      form.setFormValues(formValues, availableFormValues);
    }
  }

  private loadTestForm(
    form: AbstractFormComponent,
    formValues: any,
    availableFormValues: any
  ) {
    switch (form.ID) {
      case 1:
        formValues = {
          name: "NT Software Solutions",
          postcode: "52134",
          residence: "Herzogenrath",
          mail: "info@nt-software.de",
          phone: "0123456789101",
        };
        break;
      case 2:
        break;
      case 3:
        formValues = [
          {
            ident: "management",
            name: "Geschäftsführung",
            persons: [
              {
                position: "Geschäftsführer",
                title: null,
                gender: "male",
                fName: "Max",
                lName: "Mustermann",
                phone: "+49123456789",
                mail: "m.mustermann@mail.de"
              }
            ],
          },
          {
            ident: "production",
            name: "Produktionsleitung",
            persons: [],
          },
          {
            ident: "quality",
            name: "Qualitätsmanagement",
            persons: [],
          },
          {
            ident: "logistic",
            name: "Logistik/ Versand",
            persons: [],
          },
          {
            ident: "administration",
            name: "Verwaltung",
            persons: [],
          },
        ];
        break;
      case 4:
        break;
      case 5:
        formValues = {
          certificates: [
            {
              id: 1,
              value: true,
              name: "DIN EN ISO 9001:2015",
            },
          ],
        };
        break;
      case 6:
        availableFormValues = {
          spectrum: [
            {
              name: "Drehen",
              parameter: [
                { name: "Min Abmaße", unit: "mm" },
                { name: "Max Abmaße", unit: "mm" },
                { name: "Min Gewicht", unit: "kg" },
              ],
            },
            {
              name: "Fräsen",
              parameter: [
                { name: "Min Abmaße", unit: "mm" },
                { name: "Max Abmaße", unit: "mm" },
                { name: "Min Gewicht", unit: "kg" },
              ],
            },
            {
              name: "Schleifen",
              parameter: [
                { name: "Min Abmaße", unit: "mm" },
                { name: "Max Abmaße", unit: "mm" },
                { name: "Min Gewicht", unit: "kg" },
              ],
            },
          ],
        };
        formValues = {
          spectrum: [
            {
              name: "Drehen",
              parameter: [
                {
                  name: "Min Abmaße",
                  value: 1000,
                  unit: "mm",
                },
              ],
            },
          ],
        };
        break;

      default:
        break;
    }
    return { formValues, availableFormValues };
  }

  async saveForm() {
    let sheetData = this.currenFormSub.value.getFormValues();
    let sheetName = this.currenFormSub.value.getSheetName();
    await this.assessmentService.updateSheet(sheetName, sheetData).toPromise();
    console.log("[saveForm]", sheetName, sheetData);
    this.formSavedSub.next({
      formId: this.currenFormSub.value.ID,
      time: new Date(),
    });
  }

  constructor(private assessmentService: AssessmentService) {}
}
