import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { SUPPLIER_GENERAL_FORM } from "src/app/controller/constants/forms-config";
import { AbstractFormComponent } from "../abstract-form.component";

@Component({
  selector: "app-general",
  templateUrl: "./general.component.html",
  styleUrls: ["./general.component.css"],
  host: {
    class: "css-form",
  },
})
export class GeneralComponent extends AbstractFormComponent implements OnInit {
  form: any = {
    name: null,
    street: null,
    postcode: null,
    network: null,
    residence: null,
    phone: null,
    fax: null,
    mail: null,
    legalForm: null,
  };

  constructor(http: HttpClient) {
    super(http);
  }

  ID: number = SUPPLIER_GENERAL_FORM.ID;
  TITLE: string = SUPPLIER_GENERAL_FORM.TITLE;
  PATH: string = SUPPLIER_GENERAL_FORM.PATH;

  countryCodes: any[] = [];

  async loadCountryCodes() {
    if (!this.http) {
      return;
    }
    let countries: any[] = (await this.http
      ?.get("https://restcountries.com/v3.1/all")
      .toPromise()) as any[];
    let countryCodesTmp = countries
      .filter(
        (c) =>
          c.idd && c.idd.root && c.idd.suffixes && c.idd.suffixes.length > 0
      )
      .map((country) => ({
        flag: country.flag,
        code: `${country.idd.root}${country.idd.suffixes[0]}`,
      }))
      .sort((o1: any, o2: any) => o1.code - o2.code);

    this.countryCodes = countryCodesTmp.filter((element, index) => {
      return countryCodesTmp.indexOf(element) === index;
    });
  }

  getFormValues() {
    return this.form;
  }

  setFormValues(formValues: any): void {
    this.loadCountryCodes().then(() => {
      this.form = formValues;
    });
  }

  getSheetName(): string {
    return "general";
  }

  getCountryCode(phoneNumber: string) {
    let cc = this.countryCodes.find((cc) => phoneNumber.indexOf(cc.code) > -1);
    return cc ? cc.code : "";
  }

  getPhoneNumber(phoneNumber: string) {
    let cc = this.getCountryCode(phoneNumber);
    return phoneNumber.substring(cc.length - 1);
  }

  ngOnInit(): void {}
}
