import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttributeManagerService {
  private userSidebarOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>(
    false
  );
  public userSidebarOpen: Observable<any> = this.userSidebarOpenSubject.asObservable();

  toggleUserSidebar() {
    this.userSidebarOpenSubject.next(!this.userSidebarOpenSubject.value);
  }

  closeUserSidebar() {
    this.userSidebarOpenSubject.next(false);
  }

  private orderOverlayOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>(
    false
  );
  public orderOverlayOpen: Observable<any> = this.orderOverlayOpenSubject.asObservable();

  toggleorderOverlay() {
    this.orderOverlayOpenSubject.next(!this.orderOverlayOpenSubject.value);
  }

  private hideTopbarSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>(
    false
  );
  public hideTopbar: Observable<any> = this.hideTopbarSubject.asObservable();

  removeTopbar() {
    this.hideTopbarSubject.next(true);
  }

  showTopbar() {
    this.hideTopbarSubject.next(false);
  }

  constructor() {}
}
