<p>Bitte wählen Sie ein Fertigungsspektrum durch Hinzufügen und Entfernen der verschiedenen Kategorien.</p>
<p>Nach Hinzufügen einer Kategorie können entsprechende Eigenschaften gewählt und eingetragen werden.</p>
<app-button-to-inputs
  [enableRemoveCategory]="true"
  [enableAddCategory]="true"
  [enableRemoveCategoryDetail]="true"
  [enableAddCategoryDetail]="true"
  [(categories)]="form"
  [selectableCategories]="selectableProductionSpectrums['spectrum']"
></app-button-to-inputs>
