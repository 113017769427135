<div class="register-header">
    <img src="assets/img/logos/customer-logo.png" alt="" class="logo-register" />
    <h2>Supplier Portal</h2> 
  </div>
  <div class="register-clean">
    <div class="register-clean-container">
    <form
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="intro small">
        <!--img
          src="assets/img/logos/customer-logo.png"
          alt=""
          class="logo-register"
        /-->
        <h3 class="title register pt-2">Registrieren</h3>
        <i class="icon ion-log-in"></i>
      </div>
      <div class="register-inputs">
      <div class="input-container">
        <input
          name="companyname"
          [(ngModel)]="form.companyname"
          #companyname="ngModel"
          type="text"
          id="companyname"
          required="required"
          class="css-input-item"
        />
        <label for="companyname">Firmenname</label>
        <div class="bar"></div>
      </div>
      <div *ngIf="f.submitted && companyname.errors" class="alert alert-danger">
        <div *ngIf="companyname.errors.required">Firmenname ist erfolderlich!</div>
      </div>
      <div class="input-container">
        <input
          name="email"
          [(ngModel)]="form.email"
          type="email"
          #email="ngModel"
          id="email"
          class="css-input-item"
          required
          email
        />
        <label for="email">E-Mail für Zugangsdatenversand</label>
        <div class="bar"></div>
      </div>
      <div *ngIf="f.submitted && email.errors" class="alert alert-danger">
        <div *ngIf="email.errors.required">E-Mail ist erforderlich!</div>
        <div *ngIf="email.errors?.email">Bitte geben Sie eine gültige E-Mail Adresse an.</div>
      </div>
        <button
          [disabled]="loading"
          class="btn css-btn css-btn-primary"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          <i *ngIf="!loading" class="fas fa-sign-in-alt mr-1"></i>
          <span class="ml-1">Registrieren</span>
        </button>
      </div>
        <!--div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div-->
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && isregisterFailed"
        >
          <span>Registrieren fehlgeschlagen: {{ errorMessage }}</span>
        </div>
      </div>
    </form>
  </div>
  </div>
  