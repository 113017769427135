<div class="css-header-footer-panel css-header-panel">
  <div class="css-header-panel-control-container">
    <app-header-panel-control
      [backTask]="backTask"
      [homeTask]="homeTask"
      [lastFinalization]="lastFinalization"
    ></app-header-panel-control>
  </div>
  <div class="css-header-panel-info-container">
    <app-header-panel-info
      [infoDescription]="infoDescription"
      [progressValue]="progressValue"
      [lastSaved]="lastSaved"
    ></app-header-panel-info>
  </div>
</div>
