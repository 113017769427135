<mat-dialog-content>
  <div class="persons-container">
    <h1>{{ title | translate }}</h1>
    <div>
      <app-css-text-input
        [label]="'Position'"
        [(value)]="person['position']"
        [required]="true"
      ></app-css-text-input>
      <app-css-select
        [label]="'Titel'"
        [options]="titleValues"
        [(value)]="person['title']"
      ></app-css-select>
      <app-css-select
        [label]="'Anrede'"
        [options]="genderValues"
        [(value)]="person['gender']"
      ></app-css-select>
      <app-css-text-input
        [label]="'Vorname'"
        [(value)]="person['fName']"
        [required]="true"
      ></app-css-text-input>
      <app-css-text-input
        [label]="'Nachname'"
        [(value)]="person['lName']"
        [required]="true"
      ></app-css-text-input>
      <app-css-phone-input
        [countryCodes]="countryCodes"
        [label]="'Mobil'"
        [(value)]="person['phone']"
      ></app-css-phone-input>
      <app-css-text-input
        [label]="'E-Mail'"
        [(value)]="person['mail']"
      ></app-css-text-input>
      <span
        *ngIf="missingRequiredFields"
        style="
          display: block;
          color: #ff0000;
          font-style: italic;
          padding-top: 1em;
          padding-bottom: 1em;
        "
        >{{ "general.form-required-fields" | translate }}</span
      >
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="custom-mat-dialog-actions">
  <div class="modal-dialog-footer" *ngIf="!taskLoading">
    <app-button
      [btnType]="cancelButtonConfig.btnType"
      [dialogRef]="dialogRef"
      [title]="cancelButtonConfig.title"
      [customClasses]="'css-modal-button'"
      (click)="closeDialog()"
    ></app-button>
    <app-button
      [btnType]="saveButtonConfig.btnType"
      [dialogRef]="dialogRef"
      [title]="saveButtonConfig.title"
      [customClasses]="'css-modal-button'"
      (click)="savePerson()"
    ></app-button>
  </div>
  <div class="modal-dialog-footer" *ngIf="taskLoading">
    <button class="btn btn-custom btn-outline">
      <div class="spinner-border spinner-border-sm"></div>
    </button>
  </div>
</mat-dialog-actions>
