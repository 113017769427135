<p style="width: 100%; text-align: center">
  Bitte geben Sie hier Ihre allgemeinen Kontaktdaten an.
</p>
<p style="width: 100%; text-align: center; font-style: italic">
  {{ "general.required-description" | translate }}
</p>
<app-spinner [hidden]="!loadingFields"></app-spinner>
<div class="css-form-grid">
  <div
    *ngFor="let field of fields"
    [ngStyle]="{ 'grid-column': field.col, 'grid-row': field.row }"
  >
    <app-css-text-input
      *ngIf="field.type !== 'tel'"
      [label]="field.label"
      [(value)]="form[field['name']]"
      [type]="field.type"
      [required]="field.required"
      [disabled]="field.disabled === true"
    ></app-css-text-input>
    <app-css-phone-input
      *ngIf="field.type === 'tel'"
      [label]="field.label"
      [(value)]="form[field['name']]"
      [required]="true"
      [countryCodes]="countryCodes"
    ></app-css-phone-input>
  </div>
</div>
