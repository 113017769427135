import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ModalComponent } from "src/app/modal/modal.component";
import { Task } from "../task";

export class NestedNavigateTask implements Task {
  constructor(
    private task: Task,
    private router: Router,
    private route: any[]
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined
  ): Promise<void> {
    await this.task.execute(dialogRef);
    await this.router.navigate(this.route);
    dialogRef?.close(true);
  }
}
