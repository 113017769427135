import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonIconType } from 'src/app/enums/button-icon-type.enum';
import { ButtonType } from 'src/app/enums/button-type.enum';
import { ModalComponent } from 'src/app/modal/modal.component';
import { Task } from 'src/app/tasks/task';

export interface IButton {
  enableBackwardIcon: boolean;
  enableForwardIcon: boolean;
  btnType: ButtonType;
  dialogRef?: MatDialogRef<ModalComponent>;
  task?: Task;
  title: string;
  iconType?: ButtonIconType;
  cssStyle?: string;
  customClasses?: string;
  hidden?: boolean;
}

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.css"],
})
export class ButtonComponent implements OnInit, IButton {
  
  useImageAsIcon = false;

  @Input()
  enableBackwardIcon = false;

  @Input()
  enableForwardIcon = false;

  @Input()
  btnType: ButtonType = ButtonType.PRIMARY;

  @Input()
  dialogRef?: MatDialogRef<ModalComponent>;

  @Input()
  task?: Task;

  @Input()
  title = "";

  @Input()
  iconType?: ButtonIconType;

  @Input()
  cssStyle?: any = "";

  @Input()
  customClasses?: string = "";

  @Input()
  ident?: string = "";

  taskLoading: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  async executeTask() {
    await this.task?.execute(this.dialogRef, this);
  }

  enableTaskLoading(loading: boolean) {
    this.taskLoading = loading;
  }

  getBtnClass() {
    let clazzNames = "";

    switch (this.btnType) {
      case ButtonType.PRIMARY:
      case ButtonType.SECONDARY:
        clazzNames += this.btnType;
        break;
      default:
        break;
    }

    if (this.customClasses && this.customClasses.length > 0) {
      clazzNames += ` ${this.customClasses}`;
    }

    return clazzNames;
  }

  getIconClass(): string {
    switch (this.iconType) {
      case ButtonIconType.ADD:
      case ButtonIconType.REFRESH:
      case ButtonIconType.SAVE:
      case ButtonIconType.CLOSE:
      case ButtonIconType.REMOVE:
      case ButtonIconType.LOGOUT:
      case ButtonIconType.DOWNLOAD:
      case ButtonIconType.UPLOAD:
      case ButtonIconType.EDIT:
      case ButtonIconType.SHOW:
      case ButtonIconType.USERS:
        return this.iconType + " css-fa-icon-bigger";
      case ButtonIconType.HOME:
        return "css-clip-path-icon css-home-icon";
      case ButtonIconType.CUSTOMER_LOGO:
        this.useImageAsIcon = true;
        return this.iconType;
      default:
        return this.iconType + "";
    }
  }
}
