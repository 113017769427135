import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtTokenService } from 'src/app/auth/service/jwt-token.service';
import { ButtonComponent } from 'src/app/components/buttons/button/button.component';
import { OPENFEEDBACKS, SUPPLIERADMINOVERVIEW, USERMANAGEMENT } from 'src/app/controller/constants/supplier-admin';
import { ButtonIconType } from 'src/app/enums/button-icon-type.enum';
import { UserRoles } from 'src/app/enums/user-roles.enum';
import { ModalComponent } from 'src/app/modal/modal.component';
import { Task } from "src/app/tasks/task";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent {
  openFeedbacksBtnConfig: any = {
    ident: OPENFEEDBACKS.ID,
    icon: ButtonIconType.FILE_CHECK,
    title: OPENFEEDBACKS.TITLE,
    description: OPENFEEDBACKS.DESCRIPTION,
  };

  supplierBtnConfig: any = {
    ident: SUPPLIERADMINOVERVIEW.ID,
    icon: ButtonIconType.FILE_LINES,
    title: SUPPLIERADMINOVERVIEW.TITLE,
    description: SUPPLIERADMINOVERVIEW.DESCRIPTION,
  };

  userManagementBtnConfig: any = {
    ident: USERMANAGEMENT.ID,
    icon: ButtonIconType.USERS,
    title: USERMANAGEMENT.TITLE,
    description: USERMANAGEMENT.DESCRIPTION,
  };

  homeButtons: any[] = [this.openFeedbacksBtnConfig, this.supplierBtnConfig];

  navigateOpenFeedbacksTask: Task;
  navigateSuppliersTask: Task;
  navigateUserManagementTask: Task;

  constructor(private router: Router, private jwtTokenService: JwtTokenService) {
    this.navigateOpenFeedbacksTask = new HomeNavigateTask([OPENFEEDBACKS.PATH], router);
    this.navigateSuppliersTask = new HomeNavigateTask([SUPPLIERADMINOVERVIEW.PATH], router);
    this.navigateUserManagementTask = new HomeNavigateTask([USERMANAGEMENT.PATH], router);
    this.openFeedbacksBtnConfig["task"] = this.navigateOpenFeedbacksTask;
    this.supplierBtnConfig["task"] = this.navigateSuppliersTask; 

    let role = this.jwtTokenService.getRole();
    if (role === UserRoles.ADMIN) {
      this.userManagementBtnConfig["task"] = this.navigateUserManagementTask;
      this.homeButtons.push(this.userManagementBtnConfig);
    }
  }
}
export class HomeNavigateTask implements Task{
  constructor(private commands: string[], private router: Router) {

  }

  async execute(dialogRef?: MatDialogRef<ModalComponent, any> | undefined, buttonComponent?: ButtonComponent | undefined): Promise<void> {
    await  this.router.navigate( ["admin", ...this.commands]);
  }

}