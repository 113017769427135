import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponent, IButton } from '../button/button.component';

export interface INavigateButton extends IButton {
  route: any[];
}

@Component({
  selector: 'app-navigate-button',
  templateUrl: './navigate-button.component.html',
  styleUrls: ['./navigate-button.component.css']
})
export class NavigateButtonComponent extends ButtonComponent implements OnInit, INavigateButton {
  @Input()
  route: any[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
