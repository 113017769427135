import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ColDef } from "ag-grid-community";
import {
  CellButtonAction,
  CellButtonComponent,
} from "src/app/components/buttons/ag-grid/cell-button/cell-button.component";
import { ButtonComponent } from "src/app/components/buttons/button/button.component";
import { USERMANAGEMENT } from "src/app/controller/constants/supplier-admin";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import { UserRoles } from "src/app/enums/user-roles.enum";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "src/app/modal/modal.component";
import { ResetPasswordComponent } from "src/app/modal/user-management/reset-password/reset-password.component";
import { UserService } from "src/app/services/api/user.service";
import { CancelTask } from "src/app/tasks/impl/cancel.task";
import { Task } from "src/app/tasks/task";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.css"],
})
export class UserManagementComponent implements OnInit {
  ngOnInit(): void {
    //
  }

  api: any;

  backButtonLabel = "screen.admin.dashboard";
  title = USERMANAGEMENT.TITLE;
  description = USERMANAGEMENT.DESCRIPTION;
  navigateBackTask: Task;

  addBtnConfig: any = {
    title: "general.add",
    iconType: ButtonIconType.ADD,
  };

  addUserForm: any = {
    username: "",
    password: "",
  };

  userRowData: any[] = [];

  resetPasswordBtn: any = {
    btnType: ButtonType.PRIMARY,
    title: "general.reset",
    directSave: true,
  };

  editUserBtn: any = {
    btnType: ButtonType.PRIMARY,
    title: "screen.admin.user-management.manage-users.edit-user",
  };

  editingRowId = -1;

  userGridColDefs: ColDef[] = [
    {
      headerName: this.translate.instant(
        "screen.admin.user-management.username"
      ),
      field: "username",
      flex: 1,
      cellStyle: (params) => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      headerName: this.translate.instant(
        "screen.admin.user-management.manage-users.role"
      ),
      field: "role",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["purchasing_operator", "admin", "deactivated"],
      },
      valueFormatter: (params: any) => {
        return this.translate.instant(
          `screen.admin.user-management.manage-users.roles.${params.value}`
        );
      },
      editable: (params) => {
        return params.node.rowIndex == this.editingRowId;
      },
      cellStyle: (params) => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      headerName: this.translate.instant(
        "screen.admin.user-management.password"
      ),
      autoHeight: true,
      cellRenderer: CellButtonComponent,
      cellRendererParams: {
        onCellClick: this.onResetPWButtonClick.bind(this),
        ...this.resetPasswordBtn,
      },
    },
    {
      headerName: this.translate.instant("general.edit"),
      autoHeight: true,
      cellRenderer: CellButtonComponent,
      cellRendererParams: {
        onCellClick: this.onEditBtnClicked.bind(this),
        ...this.editUserBtn,
      },
      flex: 0,
      minWidth: 300,
    },
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.navigateBackTask = new UserManagementNavigateBackTask(router);
    this.addBtnConfig["task"] = new CreateUserTask(
      this,
      userService,
      dialog,
      translate
    );
    userService.getUsers().subscribe((users) => {
      this.userRowData = users;
    });
  }

  async onEditBtnClicked(action: CellButtonAction, params: any) {
    switch (action) {
      case CellButtonAction.CANCEL:
        this.api.stopEditing();
        this.editingRowId = -1;
        this.userService.getUsers().subscribe((users) => {
          this.userRowData = users;
        });
        break;
      case CellButtonAction.EDIT:
        this.editingRowId = params.rowIndex;
        this.api.setFocusedCell(this.editingRowId, "role");
        this.api.startEditingCell({
          rowIndex: this.editingRowId,
          colKey: "role",
        });
        break;
      case CellButtonAction.SAVE:
        this.api.stopEditing();
        this.editingRowId = -1;
        await this.userService
          .changeRole(params.data.id, params.data.role)
          .toPromise();
        let okButton = ModalButtonGenerator.createButton(
          "Ok",
          new CancelTask(),
          ButtonType.PRIMARY
        );
        this.dialog.open(ModalComponent, {
          data: {
            message: this.translate.instant(
              "screen.admin.user-management.changed-user-role"
            ),
            buttons: [okButton],
          },
        });
        break;

      default:
        //
        break;
    }
  }

  onGridReady(params: any) {
    this.api = params.api;
  }

  onResetPWButtonClick(action: string, params: any) {
    switch (action) {
      case CellButtonAction.SAVE:
        let message = '<i class="fas fa-exclamation-triangle"></i><br>';
        let savePWTask = new SaveUserPWTask(
          this.userService,
          params.data,
          this.dialog,
          this.translate
        );
        let savePWBtn = ModalButtonGenerator.createButton(
          "general.save",
          savePWTask,
          ButtonType.PRIMARY
        );
        this.dialog.open(ResetPasswordComponent, {
          data: {
            message,
            buttons: [savePWBtn, ModalButtonGenerator.createCancelButton()],
          },
        });
        break;

      default:
        break;
    }
  }
}
export class UserManagementNavigateBackTask implements Task {
  constructor(private router: Router) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    this.router.navigate(["admin"]);
  }
}
export class SaveUserPWTask implements Task {
  constructor(
    private userService: UserService,
    private userData: any,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  public password: string = "";

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    await this.userService
      .changePassword(this.userData.id, this.password)
      .toPromise();
    dialogRef?.close();
    let okButton = ModalButtonGenerator.createButton(
      "Ok",
      new CancelTask(),
      ButtonType.PRIMARY
    );
    this.dialog.open(ModalComponent, {
      data: {
        message: this.translate.instant(
          "screen.admin.user-management.changed-user-password"
        ),
        buttons: [okButton],
      },
    });
  }
}
export class CreateUserTask implements Task {
  constructor(
    private parent: UserManagementComponent,
    private userService: UserService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    await this.userService
      .createUser(
        this.parent.addUserForm.username,
        this.parent.addUserForm.password,
        UserRoles.PURCHASING_OPERATOR
      )
      .toPromise();
    this.parent.addUserForm.username = "";
    this.parent.addUserForm.password = "";
    this.userService.getUsers().subscribe((users) => {
      this.parent.userRowData = users;
    });
    let okButton = ModalButtonGenerator.createButton(
      "Ok",
      new CancelTask(),
      ButtonType.PRIMARY
    );
    this.dialog.open(ModalComponent, {
      data: {
        message: this.translate.instant(
          "screen.admin.user-management.added-user"
        ),
        buttons: [okButton],
      },
    });
  }
}
