<app-header-panel
  [backTask]="controller.backBtnTask"
  [homeTask]="controller.homeTask == null ? undefined : controller.homeTask"
  [progressValue]="controller.getProgressValue()"
  [infoDescription]="controller.getTitle()"
  [lastSaved]="controller.formSaved?.time"
  [lastFinalization]="controller.lastFinalization"
></app-header-panel>
<div class="css-form-container">
  <router-outlet
    (activate)="routeActivated($event)"
    (deactivate)="routeDeactivated($event)"
  ></router-outlet>
</div>
<app-footer-panel [buttonConfigs]="controller.footerButtons"></app-footer-panel>
