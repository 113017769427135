import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import hash from "object-hash";
import { IButton } from "../components/buttons/button/button.component";
import { ButtonIconType } from "../enums/button-icon-type.enum";
import { ButtonType } from "../enums/button-type.enum";
import { IScreenConfig } from "../interfaces/screen-config.interface";
import { AbstractFormComponent } from "../screens/supplier-form/abstract-form.component";
import { AssessmentService } from "../services/api/assessment.service";
import { FormServiceService } from "../services/form-service.service";
import { FinalizeAssessmentTask } from "../tasks/impl/finalize-assessment.task";
import { RequiredNavigateWithConditionTask } from "../tasks/impl/required-navigate-with-condition.task";
import { SaveSupplierFormTask } from "../tasks/impl/save-supplier-form.task";
import { Task } from "../tasks/task";
import {
  SUPPLIER_ASSESSMENT_FINALIZATION,
  SUPPLIER_QM_FORM,
  supplierFormConfig,
} from "./constants/forms-config";
import { NavigateWithConditionTask } from "../tasks/impl/navigate-with-condition.task";

export class SupplierFormController {
  footerButtonCount = 3;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private formService: FormServiceService,
    private translateService: TranslateService,
    private assessmentService: AssessmentService
  ) {
    formService.currentForm.subscribe((val) => {
      if (!val) {
        return;
      }
      this.formSaved = null;
      this.currentForm = val;
      this.currentFormInitialFormValues = JSON.parse(
        JSON.stringify(val.getFormValues())
      );

      if (
        val.ID === SUPPLIER_QM_FORM.ID ||
        val.ID === SUPPLIER_ASSESSMENT_FINALIZATION.ID
      ) {
        this.saveBtn.hidden = true;
      } else {
        this.saveBtn.hidden = false;
      }

      if (val.ID === SUPPLIER_ASSESSMENT_FINALIZATION.ID) {
        this.finalizeBtn.hidden = true;
      } else {
        this.finalizeBtn.hidden = false;
      }

      // trigger footer reload
      let tmpFooterButtons = this.footerButtons;
      this.footerButtons = [];
      this.footerButtons = [...tmpFooterButtons];
    });

    formService.nextForm.subscribe((val) => {
      if (!val) {
        if (this.footerButtons.length == this.footerButtonCount) {
          this.footerButtons.pop();
        }
        return;
      }
      if (this.footerButtons.length < this.footerButtonCount) {
        this.footerButtons = [...this.footerButtons, this.nextBtn];
      }
      this.nextForm = val;
      this.nextBtn.task = this.getFormNavigateTask(val?.path);
      this.nextBtn.title = this.nextForm?.title;

      this.homeTask = this.getNavigateTask(["supplier"]);
    });

    formService.lastForm.subscribe((val) => {
      if (!val) {
        this.backBtnTask = null;
        return;
      }
      this.lastForm = val;
      this.backBtnTask = this.getFormNavigateTask(val.path);

      this.homeTask = this.getNavigateTask(["supplier"]);
    });

    formService.formSaved.subscribe((val) => {
      this.formSaved = val;
      if (this.currentForm) {
        this.currentFormInitialFormValues = JSON.parse(
          JSON.stringify(this.currentForm.getFormValues())
        );
      }
    });

    this.saveBtn.task = new SaveSupplierFormTask(
      dialog,
      translateService,
      formService
    );

    this.finalizeBtn.task = new FinalizeAssessmentTask(
      dialog,
      translateService,
      formService,
      false,
      router
    );

    assessmentService.getLastFinalizationTimestamp().subscribe((val) => {
      if (val?.finTimestampt == null) {
        this.finalizeBtn.hidden = true;
      } else {
        this.finalizeBtn.hidden = false;
      }

      this.lastFinalization = val.finTimestampt;
    });
  }

  saveBtn: IButton = {
    btnType: ButtonType.SECONDARY,
    enableBackwardIcon: false,
    enableForwardIcon: false,
    title: "comp.title.save",
    iconType: ButtonIconType.SAVE,
  };

  nextBtn: IButton = {
    btnType: ButtonType.PRIMARY,
    enableBackwardIcon: false,
    enableForwardIcon: true,
    title: "",
  };

  finalizeBtn: IButton = {
    btnType: ButtonType.SECONDARY,
    enableBackwardIcon: false,
    enableForwardIcon: false,
    title: "comp.title.finalize",
    iconType: ButtonIconType.CHECK,
  };

  footerButtons = [this.finalizeBtn, this.saveBtn, this.nextBtn];

  backBtnTask: Task | null = null;

  homeTask: Task | null = null;

  currentForm?: AbstractFormComponent;
  currentFormInitialFormValues: any;
  nextForm?: IScreenConfig;
  lastForm?: IScreenConfig;
  formSaved?: any;
  lastFinalization?: any;

  getTitle(): string {
    let title = this.currentForm?.TITLE;
    return title ? title : "";
  }

  getProgressValue(): number {
    let progressValue = supplierFormConfig.find(
      (screen) => screen.id == this.currentForm?.ID
    )?.progressValue;

    if (progressValue) {
      return Math.round(progressValue * 100);
    }

    return 0;
  }

  getFormNavigateTask(path: string) {
    return this.getNavigateTask(["supplier-form", path]);
  }

  getNavigateTask(route: any[]) {
    let unsavedChangesMsg = "dialog.unsavedChanges";
    let saveTask: Task | null;
    let validationFunction: () => boolean;
    if (this.currentForm && this.currentForm.ID === SUPPLIER_QM_FORM.ID) {
      saveTask = null;
      validationFunction = () => true;
    } else {
      saveTask = new SaveSupplierFormTask(
        this.dialog,
        this.translateService,
        this.formService,
        false
      );
      validationFunction = this.hasUnsavedChanges.bind(this);
    }

    // No require validation for home navigation
    if (route[0] == "supplier") {
      return new NavigateWithConditionTask(
        this.dialog,
        this.router,
        route,
        unsavedChangesMsg,
        this.translateService,
        saveTask,
        validationFunction
      );
    } else {
      return new RequiredNavigateWithConditionTask(
        this.dialog,
        this.router,
        route,
        unsavedChangesMsg,
        this.translateService,
        saveTask,
        validationFunction,
        this.currentForm
      );
    }
  }

  hasUnsavedChanges(): boolean {
    let formHash = hash(this.currentForm?.getFormValues());
    let initialFormHash = hash(this.currentFormInitialFormValues);
    return formHash === initialFormHash;
  }

  loadScreenValues() {}

  saveScreenValues() {}
}
