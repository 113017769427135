import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { canActivate, canActivateLogin } from "./auth/guard/authorize.guard";
import {
  SUPPLIER_ASSESSMENT_FINALIZATION,
  SUPPLIER_CONTACTS_FORM,
  SUPPLIER_EMPLOYEES_FORM,
  SUPPLIER_GENERAL_FORM,
  SUPPLIER_PRODUCTIONSPECTRE_FORM,
  SUPPLIER_QM_FORM,
  SUPPLIER_UTILIZATIONRATE_FORM,
} from "./controller/constants/forms-config";
import {
  OPENFEEDBACKS,
  SUPPLIERADMINOVERVIEW,
  USERMANAGEMENT,
} from "./controller/constants/supplier-admin";
import { AdminDashboardComponent } from "./screens/admin/admin-dashboard/admin-dashboard.component";
import { OpenAssessmentsComponent } from "./screens/admin/open-assessments/open-assessments.component";
import { OverviewComponent } from "./screens/admin/overview/overview.component";
import { SupplierAdminOverviewComponent } from "./screens/admin/supplier-admin-overview/supplier-admin-overview.component";
import { UserManagementComponent } from "./screens/admin/user-management/user-management.component";
import { FinalizationEndComponent } from "./screens/finalization-end/finalization-end.component";
import { LoginComponent } from "./screens/login/login.component";
import { RegistrationEndComponent } from "./screens/registration-end/registration-end.component";
import { ContactsComponent } from "./screens/supplier-form/contacts/contacts.component";
import { EmployeesComponent } from "./screens/supplier-form/employees/employees.component";
import { FinalizationComponent } from "./screens/supplier-form/finalization/finalization.component";
import { GeneralComponent } from "./screens/supplier-form/general/general.component";
import { ProductionSpectreComponent } from "./screens/supplier-form/production-spectre/production-spectre.component";
import { QualityManagementComponent } from "./screens/supplier-form/quality-management/quality-management.component";
import { SupplierFormComponent } from "./screens/supplier-form/supplier-form.component";
import { UtilizationRateProductionLinesComponent } from "./screens/supplier-form/utilization-rate-production-lines/utilization-rate-production-lines.component";
import { SupplierOverviewComponent } from "./screens/supplier-overview/supplier-overview.component";
import { SupplierRegisterComponent } from "./screens/supplier-register/supplier-register.component";

//import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  {
    path: "supplier",
    component: SupplierOverviewComponent,
    canActivate: [canActivate],
  },
  {
    path: "supplier-form",
    component: SupplierFormComponent,
    canActivate: [canActivate],
    children: [
      { path: "", redirectTo: SUPPLIER_GENERAL_FORM.PATH, pathMatch: "full" },
      {
        path: SUPPLIER_GENERAL_FORM.PATH,
        component: GeneralComponent,
      },
      {
        path: SUPPLIER_EMPLOYEES_FORM.PATH,
        component: EmployeesComponent,
      },
      {
        path: SUPPLIER_CONTACTS_FORM.PATH,
        component: ContactsComponent,
      },
      {
        path: SUPPLIER_UTILIZATIONRATE_FORM.PATH,
        component: UtilizationRateProductionLinesComponent,
      },
      {
        path: SUPPLIER_QM_FORM.PATH,
        component: QualityManagementComponent,
      },
      {
        path: SUPPLIER_PRODUCTIONSPECTRE_FORM.PATH,
        component: ProductionSpectreComponent,
      },
      {
        path: SUPPLIER_ASSESSMENT_FINALIZATION.PATH,
        component: FinalizationComponent,
      },
    ],
  },
  {
    path: "finalizationEnd",
    component: FinalizationEndComponent,
  },
  {
    path: "admin",
    component: OverviewComponent,
    pathMatch: "prefix",
    canActivate: [canActivate],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        component: AdminDashboardComponent,
      },
      {
        path: OPENFEEDBACKS.PATH,
        component: OpenAssessmentsComponent,
      },
      {
        path: SUPPLIERADMINOVERVIEW.PATH,
        component: SupplierAdminOverviewComponent,
      },
      {
        path: USERMANAGEMENT.PATH,
        component: UserManagementComponent,
      },
    ],
  },
  { path: "login", component: LoginComponent, canActivate: [canActivateLogin] },
  //{ path: 'detailOrderitem/:id/:orderitemno', component: OrderItemDetailComponent, canActivate: [AuthorizeGuard] },

  // otherwise redirect to home
  { path: "", redirectTo: "login", pathMatch: "full" },

  {
    path: "supplier-register",
    component: SupplierRegisterComponent,
  },
  {
    path: "register-end",
    component: RegistrationEndComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
