<p>Bitte geben Sie die Anzahl Ihrer Beschäftigten in den angegebenen Bereichen an.</p>
<p style="width: 100%; text-align: center;font-style: italic;">
  {{ "general.required-description" | translate }}
</p>
<app-spinner [hidden]="!loadingFields"></app-spinner>
<app-css-text-input
  *ngFor="let field of fields"
  [label]="field.label"
  [(value)]="form[field['name']]"
  [type]="field.type"
  [required]="field.required"
></app-css-text-input>
