<app-splash-screen></app-splash-screen>
<router-outlet></router-outlet>
<app-button
  *ngIf="isLoggedIn"
  [btnType]="logoutBtnConfig.btnType"
  [title]="logoutBtnConfig.title"
  [iconType]="logoutBtnConfig.iconType"
  [customClasses]="'css-logout-btn-large'"
  (click)="logout()"
></app-button>
<app-button
  *ngIf="isLoggedIn"
  [btnType]="logoutBtnConfig.btnType"
  [iconType]="logoutBtnConfig.iconType"
  [customClasses]="'css-logout-btn-small'"
  (click)="logout()"
></app-button>
