<div class="css-input-outer-container">
  <div class="css-input-container">
    <input
      class="css-input-item"
      type="file"
      (change)="selectFile($event)"
      accept=".jpg, .jpeg, .png, .pdf"
    />
    <app-button
      style="display: block"
      [cssStyle]="'height: 100%;align-items: center;'"
      [title]="'Upload'"
      (click)="upload()"
    ></app-button>
  </div>

  <div *ngIf="currentFile" class="progress my-3">
    <div
      class="progress-bar progress-bar-info progress-bar-striped"
      role="progressbar"
      attr.aria-valuenow="{{ progress }}"
      aria-valuemin="0"
      aria-valuemax="100"
      [ngStyle]="{ width: progress + '%' }"
    >
      {{ progress }}%
    </div>
  </div>
  <div *ngIf="message" class="alert alert-secondary" role="alert">
    {{ message }}
  </div>
</div>
