import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UploadFilesService {
  constructor(private http: HttpClient) {}

  uploadCertificate(file: File, fileIdent: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    // Add the custom header with additional information
    const headers = new HttpHeaders().set("certificate", fileIdent);
    formData.append("certificates", file);

    const req = new HttpRequest(
      "POST",
      `${environment.API_ENDPOINT}/uploads`,
      formData,
      {
        reportProgress: true,
        responseType: "json",
        headers: headers,
      }
    );

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${environment.API_ENDPOINT}/files`);
  }
}
