import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from 'src/app/components/buttons/button/button.component';
import { ModalButtonGenerator } from '../../modal/buttons/impl/modal-button-generator';
import { ModalComponent } from '../../modal/modal.component';
import { Task } from '../task';

export abstract class AbstractTask implements Task {
  constructor(protected dialog: MatDialog, protected translate: TranslateService) { }

  protected abstract executeAction(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined
  ): Promise<void>;

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent,
  ): Promise<void> {
    try {
      buttonComponent?.enableTaskLoading(true);
      await this.executeAction(dialogRef);
      buttonComponent?.enableTaskLoading(false);
    } catch (err: any) {
      if (err.error.code) {
        err.error.message = this.translate.instant(`error-messages.${err.error.code}`, err.error.messages);
      }
      else {
        err.error.message = this.translate.instant(`error-messages.InternalError`, {});
      }

      let message = '<i class="fas fa-exclamation-triangle"></i><br>' + err.error.message;
      const dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message,
          buttons: [ModalButtonGenerator.createCancelButton()],
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        console.log('The dialog was closed');
      });
    }
  }
}
