import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "src/app/modal/modal.component";
import { AbstractFormComponent } from "src/app/screens/supplier-form/abstract-form.component";
import { Task } from "../task";
import { CancelTask } from "./cancel.task";
import { NavigateWithConditionTask } from "./navigate-with-condition.task";

export class RequiredNavigateWithConditionTask extends NavigateWithConditionTask {
  constructor(
    dialog: MatDialog,
    router: Router,
    route: any[],
    dialogMessage: string,
    translateService: TranslateService,
    saveTask: Task | null,
    condition: () => boolean,
    private formComponent?: AbstractFormComponent
  ) {
    super(
      dialog,
      router,
      route,
      dialogMessage,
      translateService,
      saveTask,
      condition
    );
  }

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined
  ): Promise<void> {
    if (this.hasUnfilledRequiredFields()) {
      const dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message: this.translateService.instant(
            "general.form-required-fields"
          ),
          buttons: [
            ModalButtonGenerator.createButton(
              "Ok",
              new CancelTask(),
              ButtonType.PRIMARY
            ),
          ],
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        console.log("The dialog was closed");
      });
      return;
    }

    super.execute(dialogRef);
  }

  hasUnfilledRequiredFields(): boolean {
    if (this.formComponent) {
      let form = this.formComponent.getRequiredFormValues();
      if (form && Object.keys(form).length > 0) {
        for (let i = 0; i < Object.keys(form).length; i++) {
          let key = Object.keys(form)[i];
          let value = form[key];
          if (
            this.formComponent.requiredFields.indexOf(key) > -1 &&
            (!value || value.length == 0)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
