export enum ButtonIconType {
  ADD = 'fa fa-plus',
  REFRESH = 'fa fa-refresh',
  HOME = 'home-icon',
  SAVE = 'fa-solid fa-floppy-disk',
  CLOSE = 'fa-solid fa-xmark',
  REMOVE = 'fa-solid fa-trash',
  LOGOUT = 'fa-solid fa-arrow-right-from-bracket',
  DOWNLOAD = 'fa-solid fa-download',
  CHECK = 'fa-solid fa-check',
  UPLOAD = 'fa-solid fa-cloud-arrow-up',
  FILE_LINES = 'fa-solid fa-file-lines',
  FILE_CHECK = 'fa-solid fa-file-circle-check',
  EDIT = 'fa-solid fa-pen-to-square',
  SHOW = 'fa-solid fa-eye',
  USERS = 'fa-solid fa-users',
  CUSTOMER_LOGO = 'assets/img/logos/customer-logo.png'
}
