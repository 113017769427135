import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const AUTH_API = environment.API_ENDPOINT + "/prodspectrum";

@Injectable({
  providedIn: "root",
})
export class ProdSpectrumService {
  constructor(private http: HttpClient) {}

  findAll(): any {
    return this.http.get(AUTH_API);
  }
}
