import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CssTextInputComponent } from "../css-text-input/css-text-input.component";

@Component({
  selector: "app-css-phone-input",
  templateUrl: "./css-phone-input.component.html",
  styleUrls: ["./css-phone-input.component.css"],
})
export class CssPhoneInputComponent
  extends CssTextInputComponent
  implements OnInit
{
  constructor(private http: HttpClient, translateService: TranslateService) {
    super();
    this.type = "tel";
  }

  @Input()
  countryCodes: any[] = [];

  countryCode = "+49";
  phoneNumber = "";

  ngOnInit(): void {}

  selectionChanged(e: any) {
    this.logger.log("country code changed", e.target.value);
    this.countryCode = e.target.value;
    this.valueChanged();
  }

  phoneNumberChanged(e: any) {
    this.logger.log("phone number changed", e.target.value);
    this.phoneNumber = e.target.value;
    this.valueChanged();
  }

  valueChanged() {
    this.logger.log("valueChanged", {
      code: this.countryCode,
      phoneNumber: this.phoneNumber,
    });
    if (!this.phoneNumber || this.phoneNumber.length == 0) {
      this.valueChange.emit("");
      return;
    }
    this.valueChange.emit(`${this.countryCode}${this.phoneNumber}`);
  }

  getCountryCode(phoneNumber: string) {
    if (!this.countryCode || this.countryCode.length == 0) {
      let cc = this.countryCodes.find((cc) => phoneNumber.indexOf(cc.code) > -1);
      this.countryCode = cc ? cc.code : "X";
    }
    
    return this.countryCode;
  }

  getPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      let cc = this.getCountryCode(phoneNumber);
      return phoneNumber.substring(cc.length);
    }

    return null;
  }
}
