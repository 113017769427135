<div class="master-detail-buttons-container">
  <div
    *ngFor="let topic of topics"
    style="display: flex; flex-direction: column; gap: .5em;"
  >
    <h2>{{ topic.label | translate }}</h2>
    <div
      class="master-detail-button-container"
      *ngFor="let category of categories | topicFilter: topic.name"
    >
      <app-button
        *ngIf="enableRemoveCategory"
        [iconType]="removeCategoryIconType"
        (click)="removeCategory(category.name)"
        [cssStyle]="'height: 100%; align-items: center;'"
      ></app-button>
      <app-button
        [enableForwardIcon]="category.name === currentSelectedCategory.name"
        [title]="getTitle(category.label)"
        [customClasses]="getCategoryBtnClass(category.name)"
        (click)="selectCategory(category)"
        class="w-100"
      ></app-button>
    </div>
  </div>
  <div *ngIf="enableAddCategory" class="master-detail-button-container mt-3">
    <select
      [(ngModel)]="addCategorySelectedOption"
      (change)="onAddCategorySelectionChanged()"
      name="selectCategories"
      id="selectCategories"
      class="css-select h-100"
    >
      <optgroup *ngFor="let topic of selectableTopics" label="{{ topic.label | translate }}">
        <option
          *ngFor="let selectableCategory of selectableCategories | topicFilter: topic.name"
          [value]="selectableCategory.name"
        >
          {{ getTitle(selectableCategory.label) }}
        </option>
      </optgroup>
      <optgroup label="{{ 'general.category' | translate }}">
        <option value="customCategory">
          {{
            "comp.title.button-to-inputs.add-custom-category-option" | translate
          }}
        </option>
      </optgroup>
    </select>
    <app-button
      *ngIf="!enableAddIndividualCategory"
      [btnType]="addBtnConfig.btnType"
      [title]="addBtnConfig.title"
      [iconType]="addBtnConfig.iconType"
      (click)="addCategory()"
    ></app-button>
  </div>
  <div
    *ngIf="enableAddIndividualCategory"
    class="master-detail-button-container"
  >
    <app-css-text-input
      [(value)]="addIndividualCategoryValue"
    ></app-css-text-input>
    <app-button
      [btnType]="addBtnConfig.btnType"
      [title]="addBtnConfig.title"
      [iconType]="addBtnConfig.iconType"
      (click)="addIndividualCategory()"
    ></app-button>
  </div>
</div>
<div
  *ngIf="currentSelectedCategory.name"
  class="master-detail-inputs-container"
>
  <h2>{{ getTitle(currentSelectedCategory.label) }}</h2>
  <div
    class="master-detail-button-container"
    *ngFor="let detail of currentSelectedCategory['parameter']"
  >
    <app-css-text-input
      *ngIf="detail.type !== 'select' && detail.unit !== 'checkbox'"
      [label]="getTitle(detail.label)"
      [unit]="detail.unit"
      [type]="detail.type || 'text'"
      [(value)]="detail.value"
      [required]="detail.mandatory"
      class="w-100"
    ></app-css-text-input>
    <app-css-select
      *ngIf="detail.type === 'select'"
      [options]="detail.values"
      [label]="detail.label"
      [name]="detail.name"
      [(value)]="detail.value"
      class="w-100"
    ></app-css-select>
    <app-css-checkbox
      *ngIf="detail.unit === 'checkbox'"
      [(value)]="detail.value"
      [disabled]="true"
    >
    </app-css-checkbox>
    <app-button
      *ngIf="enableRemoveCategoryDetail && !detail.mandatory"
      [btnType]="removeCategoryDetailButtonType"
      [iconType]="removeCategoryIconType"
      (click)="removeCategoryDetail(detail.name)"
      class="remove-category-detail-btn"
    ></app-button>
  </div>
  <span
    style="
      margin-top: 2em;
      padding-bottom: 0.2em;
      border-bottom: 1px solid var(--secondary);
    "
    >{{
      "comp.title.button-to-inputs.add-custom-parameter-label" | translate
    }}</span
  >
  <div
    *ngIf="
      enableAddCategoryDetail &&
      currentSelectableCategory &&
      currentSelectableCategory['parameter']
    "
    class="master-detail-button-container add-category-parameter-container"
    [ngStyle]="{
      'padding-bottom': enableAddIndividualCategoryParameter ? '0' : '1em'
    }"
  >
    <select
      [(ngModel)]="addCategoryDetailSelectedOption"
      (change)="onAddCategoryParamterSelectionChanged()"
      name="selectCategoryDetail"
      id="selectCategoryDetail"
      class="css-select h-100"
    >
      <optgroup label="{{ 'general.attribute' | translate }}">
        <option
          *ngFor="
            let selectableDetailCategory of getSelectableDetailCategories(
              currentSelectableCategory['parameter']
            )
          "
          [ngValue]="selectableDetailCategory"
        >
          {{ getTitle(selectableDetailCategory.label) }}
        </option>
        <option value="customCategoryParameter">
          {{
            "comp.title.button-to-inputs.add-custom-category-option" | translate
          }}
        </option>
      </optgroup>
    </select>
    <app-button
      *ngIf="!enableAddIndividualCategoryParameter"
      [btnType]="addBtnConfig.btnType"
      [title]="addBtnConfig.title"
      [iconType]="addBtnConfig.iconType"
      (click)="addCategoryDetail()"
    ></app-button>
  </div>
  <div
    *ngIf="enableAddIndividualCategoryParameter"
    class="add-individual-category-parameter-container"
  >
    <app-css-text-input
      [(value)]="addIndividualCategoryParameterValue['name']"
      [label]="'comp.title.button-to-inputs.parameter.name'"
      [required]="true"
    ></app-css-text-input>
    <app-css-text-input
      [(value)]="addIndividualCategoryParameterValue['unit']"
      [label]="'comp.title.button-to-inputs.parameter.unit'"
      [placeholder]="
        'comp.title.button-to-inputs.add-custom-parameter-unit-placeholder'
          | translate
      "
    ></app-css-text-input>
    <app-button
      [btnType]="addBtnConfig.btnType"
      [title]="addBtnConfig.title"
      [iconType]="addBtnConfig.iconType"
      [cssStyle]="'margin-top: .7em; margin-bottom: 1em;'"
      (click)="addIndividualCategoryParameter()"
    ></app-button>
  </div>
</div>
