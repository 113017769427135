import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { SUPPLIER_UTILIZATIONRATE_FORM } from "src/app/controller/constants/forms-config";
import { AbstractFormComponent } from "../abstract-form.component";

@Component({
  selector: "app-utilization-rate-production-lines",
  templateUrl: "./utilization-rate-production-lines.component.html",
  styleUrls: ["./utilization-rate-production-lines.component.css"],
  host: {
    class: "css-form",
  },
})
export class UtilizationRateProductionLinesComponent
  extends AbstractFormComponent
  implements OnInit
{
  form: any = {};

  utilizationRateOptionName = "utilizationRateOptionName";
  utilizationRateOptionTitle = "Betriebsart";

  daysPerWeekInput = {
    label: "screen.utilizationRateProductionLines-labels.daysPerWeek",
  };

  constructor(http: HttpClient) {
    super(http);
  }

  ID: number = SUPPLIER_UTILIZATIONRATE_FORM.ID;
  TITLE: string = SUPPLIER_UTILIZATIONRATE_FORM.TITLE;
  PATH: string = SUPPLIER_UTILIZATIONRATE_FORM.PATH;

  getFormValues() {
    return this.form;
  }

  setFormValues(formValues: any): void {
    this.form = formValues;
  }

  getSheetName(): string {
    return "utilizationRate";
  }

  ngOnInit(): void {}
}
