import { formatDate } from "@angular/common";
import { Component, Inject, LOCALE_ID } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ColDef, ValueGetterParams } from "ag-grid-community"; // Column Definition Type Interface
import { CellActionsComponent } from "src/app/components/buttons/ag-grid/cell-actions/cell-actions.component";
import {
  ButtonComponent,
  IButton,
} from "src/app/components/buttons/button/button.component";
import { SUPPLIERADMINOVERVIEW } from "src/app/controller/constants/supplier-admin";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "src/app/modal/modal.component";
import { AssessmentService } from "src/app/services/api/assessment.service";
import { CancelTask } from "src/app/tasks/impl/cancel.task";
import { Task } from "src/app/tasks/task";

@Component({
  selector: "app-supplier-admin-overview",
  templateUrl: "./supplier-admin-overview.component.html",
  styleUrls: ["./supplier-admin-overview.component.css"],
})
export class SupplierAdminOverviewComponent {
  ID = SUPPLIERADMINOVERVIEW.ID;
  PATH = SUPPLIERADMINOVERVIEW.PATH;
  title = SUPPLIERADMINOVERVIEW.TITLE;
  description = SUPPLIERADMINOVERVIEW.DESCRIPTION;
  comp = SupplierAdminOverviewComponent.name;

  private _selectedAssessment: any | null = null;

  auditedAssessment: any = {
    assessment_to_compare: null,
  };

  eapNoValue = "";

  backButtonLabel = "screen.admin.dashboard";

  set selectedAssessment(val: any | null) {
    this._selectedAssessment = val;
    if (val == null) {
      this.auditedAssessment = { assessment_to_compare: null };
      this.title = SUPPLIERADMINOVERVIEW.TITLE;
      this.description = SUPPLIERADMINOVERVIEW.DESCRIPTION;
      this.backButtonLabel = "screen.admin.dashboard";
    } else {
      this.title = val["company_name"];
      this.description = "screen.admin.feedback-detail.check-description";
      this.backButtonLabel = SUPPLIERADMINOVERVIEW.TITLE;
      this.loadAuditedAssessment(val);
    }
  }

  get selectedAssessment(): any | null {
    return this._selectedAssessment;
  }

  navigateBackTask: Task;
  syncEAPTask: Task;

  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private assessmentService: AssessmentService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.navigateBackTask = new SupplierOverviewNavigateBackTask(
      this,
      router,
      assessmentService
    );
    this.syncEAPTask = new SyncEAPTask(
      this,
      assessmentService,
      dialog,
      translate
    );
    assessmentService.getAuditedAssessments().subscribe((d) => {
      if (d && d.length > 0) {
        this.rowData = d;
      }
    });
  }

  ngOnInit(): void {
    this.colDefs = [
      {
        headerName: this.headerTranslation("supplier"),
        field: "company_name",
        flex: 1,
      },
      {
        headerName: this.headerTranslation("audited"),
        field: "finalization_timestamp",
        cellRenderer: (params: any) => {
          return this.formatDateCell(params.value);
        },
        flex: 1,
      },
      {
        headerName: this.headerTranslation("actions"),
        field: "actions",
        flex: 1,
        autoHeight: true,
        valueGetter: (params: ValueGetterParams) =>
          this.actionsValueGetter(params),
        cellRenderer: CellActionsComponent,
      },
    ];
  }

  // Row Data: The data to be displayed.
  rowData = [];

  async loadAuditedAssessment(assessment: any) {
    let a = await this.assessmentService
      .getAssessmentsForAuditComparison(assessment["assessmentId"])
      .toPromise();

    let { assessment_to_compare } = a;
    this.auditedAssessment["assessment_to_compare"] = assessment_to_compare;

    let eapKey = await this.assessmentService
      .getEAPKey(assessment_to_compare["id"])
      .toPromise();
    if (eapKey) {
      this.eapNoValue = eapKey as string;
    }
  }

  formatDateCell(dateString: string): string {
    if (!dateString) return ""; // handle null, undefined, etc.
    return formatDate(dateString, "dd.MM.yyyy HH:mm", this.locale);
  }

  headerTranslation(translateKey: string) {
    return this.translate.instant(
      `screen.admin.supplier-overview.table-columns.${translateKey}`
    );
  }

  actionsValueGetter(params: ValueGetterParams) {
    let showBtnConfig: IButton = {
      btnType: ButtonType.SECONDARY,
      title: "screen.admin.supplier-overview.table-columns.action-show",
      enableBackwardIcon: false,
      enableForwardIcon: false,
      iconType: ButtonIconType.SHOW,
      task: new DisplayActionTask(this, params.data, this.router),
    };

    params.data["actions"] = [showBtnConfig];
    return params;
  }
}
export class DisplayActionTask implements Task {
  constructor(
    private parent: SupplierAdminOverviewComponent,
    private assessment: any,
    private router: Router
  ) {}

  execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    console.log("selected assessment", this.assessment);
    this.parent.selectedAssessment = this.assessment;
    return Promise.resolve();
  }
}
export class SupplierOverviewNavigateBackTask implements Task {
  constructor(
    private parent: SupplierAdminOverviewComponent,
    private router: Router,
    private assessmentService: AssessmentService
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    if (this.parent.selectedAssessment) {
      this.parent.selectedAssessment = null;
      this.parent.rowData = [];
      this.assessmentService.getAuditedAssessments().subscribe((d) => {
        if (d && d.length > 0) {
          this.parent.rowData = d;
        }
      });
    } else {
      this.router.navigate(["admin"]);
    }
  }
}
export class SyncEAPTask implements Task {
  constructor(
    private parent: SupplierAdminOverviewComponent,
    private assessmentService: AssessmentService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    let assessmentId =
      this.parent.auditedAssessment["assessment_to_compare"]["id"];
    let eapId = this.parent.eapNoValue;

    if (
      !assessmentId ||
      assessmentId.length == 0 ||
      !eapId ||
      eapId.length == 0
    ) {
      return;
    }

    await this.assessmentService
      .setEAPKey(
        this.parent.auditedAssessment["assessment_to_compare"]["id"],
        this.parent.eapNoValue
      )
      .toPromise();
    let okButton = ModalButtonGenerator.createButton(
      "Ok",
      new CancelTask(),
      ButtonType.PRIMARY
    );
    this.dialog.open(ModalComponent, {
      data: {
        message: this.translate.instant(
          "screen.admin.supplier-overview.synchronized-eap"
        ),
        buttons: [okButton],
      },
    });
  }
}
