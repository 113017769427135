import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IButton } from '../../button/button.component';

@Component({
  selector: 'app-cell-actions',
  templateUrl: './cell-actions.component.html',
  styleUrls: ['./cell-actions.component.css']
})
export class CellActionsComponent implements ICellRendererAngularComp {
  actions: IButton[] = [];

  agInit(params: ICellRendererParams<any, any, any>): void {
    if (params && params.data && params.data.actions && params.data.actions.length > 0) {
      this.actions = params.data.actions;
    }
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

}
