import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import { ModalComponent } from "src/app/modal/modal.component";
import { FormServiceService } from "src/app/services/form-service.service";
import { AbstractTask } from "./abstract.task";
import { CancelTask } from "./cancel.task";

export class SaveSupplierFormTask extends AbstractTask {
  
  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    private formService: FormServiceService,
    private showSuccessDialog: boolean = true
  ) {
    super(dialog, translate);
  }

  protected async executeAction(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined
  ): Promise<void> {
    await this.formService.saveForm();
    if (this.showSuccessDialog) {
      dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message: this.translate.instant("screen.formSaved"),
          buttons: [
            ModalButtonGenerator.createButton(
              "Ok",
              new CancelTask(),
              ButtonType.PRIMARY
            ),
          ],
        },
      });
  
      await dialogRef.afterClosed().toPromise();
      console.log("The dialog was closed");
    }
  }
}
