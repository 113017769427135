import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { JwtTokenService } from "src/app/auth/service/jwt-token.service";
import { IButton } from "src/app/components/buttons/button/button.component";
import {
  SUPPLIER_CONTACTS_FORM,
  SUPPLIER_EMPLOYEES_FORM,
  SUPPLIER_GENERAL_FORM,
  SUPPLIER_PRODUCTIONSPECTRE_FORM,
  SUPPLIER_QM_FORM
} from "src/app/controller/constants/forms-config";
import { ButtonType } from "src/app/enums/button-type.enum";
import { AssessmentService } from "src/app/services/api/assessment.service";
import { NavigateWithConditionTask } from "src/app/tasks/impl/navigate-with-condition.task";


@Component({
  selector: "app-supplier-overview",
  templateUrl: "./supplier-overview.component.html",
  styleUrls: ["./supplier-overview.component.css"],
  host: {
    class: "supplier-overview",
  },
})
export class SupplierOverviewComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
    private jwtTokenService: JwtTokenService,
    private assessmentService: AssessmentService
  ) {
    let user = jwtTokenService.getUser();
    if (user && user.supplierInfos) {
      this.supplierInfos = user.supplierInfos;
    }

    this.assessmentService.getLastFinalizationTimestamp().subscribe((data: any) => {
      this.finTimestamp = data.finTimestampt;
    })
  }

  supplierInfos = [];

  finTimestamp: any;

  controls: IButton[] = [
    {
      btnType: ButtonType.PRIMARY,
      enableBackwardIcon: false,
      enableForwardIcon: true,
      title: "screen.general",
      task: new NavigateWithConditionTask(
        this.dialog,
        this.router,
        ["supplier-form", SUPPLIER_GENERAL_FORM.PATH],
        "",
        this.translateService,
        null,
        () => true
      ),
    },
    {
      btnType: ButtonType.PRIMARY,
      enableBackwardIcon: false,
      enableForwardIcon: true,
      title: "screen.employees",
      task: new NavigateWithConditionTask(
        this.dialog,
        this.router,
        ["supplier-form", SUPPLIER_EMPLOYEES_FORM.PATH],
        "",
        this.translateService,
        null,
        () => true
      ),
    },
    {
      btnType: ButtonType.PRIMARY,
      enableBackwardIcon: false,
      enableForwardIcon: true,
      title: "screen.contacts",
      task: new NavigateWithConditionTask(
        this.dialog,
        this.router,
        ["supplier-form", SUPPLIER_CONTACTS_FORM.PATH],
        "",
        this.translateService,
        null,
        () => true
      ),
    },
    /*
    {
      btnType: ButtonType.PRIMARY,
      enableBackwardIcon: false,
      enableForwardIcon: true,
      title: "screen.utilizationRateProductionLines",
      task: new NavigateWithConditionTask(
        this.dialog,
        this.router,
        ["supplier-form", SUPPLIER_UTILIZATIONRATE_FORM.PATH],
        "",
        this.translateService,
        null,
        () => true
      ),
    },
    */
    {
      btnType: ButtonType.PRIMARY,
      enableBackwardIcon: false,
      enableForwardIcon: true,
      title: "screen.qualityManagement",
      task: new NavigateWithConditionTask(
        this.dialog,
        this.router,
        ["supplier-form", SUPPLIER_QM_FORM.PATH],
        "",
        this.translateService,
        null,
        () => true
      ),
    },
    {
      btnType: ButtonType.PRIMARY,
      enableBackwardIcon: false,
      enableForwardIcon: true,
      title: "screen.productionSpectre",
      task: new NavigateWithConditionTask(
        this.dialog,
        this.router,
        ["supplier-form", SUPPLIER_PRODUCTIONSPECTRE_FORM.PATH],
        "",
        this.translateService,
        null,
        () => true
      ),
    },
  ];

  ngOnInit(): void {}
}
