import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-panel-info',
  templateUrl: './header-panel-info.component.html',
  styleUrls: ['./header-panel-info.component.css']
})
export class HeaderPanelInfoComponent implements OnInit {

  @Input()
  progressValue: number = 0;

  @Input()
  infoDescription: string = "";

  @Input()
  lastSaved?: Date;

  constructor() { }

  ngOnInit(): void {
  }

}
