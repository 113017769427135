<div class="css-header-footer-panel css-footer-panel">
  <div
    *ngFor="let btn of buttonConfigs | filterHiddenButtons"
    class="css-footer-panel-control-container"
  >
    <app-button
      [title]="btn.title"
      [enableBackwardIcon]="btn.enableBackwardIcon"
      [enableForwardIcon]="btn.enableForwardIcon"
      [btnType]="btn.btnType"
      [dialogRef]="btn.dialogRef"
      [task]="btn.task"
      [iconType]="btn.iconType"
      [cssStyle]="btn.cssStyle"
      [customClasses]="btn.customClasses"
      style="width: 100%"
    ></app-button>
  </div>
</div>
