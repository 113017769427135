import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserRoles } from "src/app/enums/user-roles.enum";
import { environment } from "src/environments/environment";

const AUTH_API = environment.API_ENDPOINT + "/user/";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<any> {
    return this.http.get(AUTH_API);
  }

  createUser(
    username: string,
    password: string,
    role: string
  ): Observable<any> {
    return this.http.post(AUTH_API, {
      username: username,
      password: password,
      role: role,
    });
  }

  changeRole(userId: string, role: UserRoles) {
    return this.http.patch(`${AUTH_API}/${userId}/role`, {
      role
    });
  }

  changePassword(userId: string, password: string) {
    return this.http.patch(`${AUTH_API}/${userId}/changePassword`, {
      newPassword: password
    });
  }

  setPassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(AUTH_API + "changePW", {
      oldPW: oldPassword,
      newPW: newPassword,
    });
  }

  register(companyname: string, email: string): Observable<any> {
    return this.http.post(AUTH_API + "register", {
      companyname,
      email,
    });
  }
}
