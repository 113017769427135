<div class="feedback-detail-container">
  <div class="feedback-detail-section">
    <span>{{ "screen.general" | translate }}</span>
    <div class="open-feedbacks-container">
      <!-- The AG Grid component -->
      <ag-grid-angular
        class="ag-theme-alpine"
        [domLayout]="'autoHeight'"
        [rowData]="rowDataGeneral"
        [columnDefs]="colDefsGeneral"
        [stopEditingWhenCellsLoseFocus]="true"
        [singleClickEdit]="screenMode == 'audit'"
      >
      </ag-grid-angular>
    </div>
  </div>
  <div class="feedback-detail-section">
    <span>{{ "screen.employees" | translate }}</span>
    <div class="open-feedbacks-container">
      <!-- The AG Grid component -->
      <ag-grid-angular
        class="ag-theme-alpine"
        [domLayout]="'autoHeight'"
        [rowData]="rowDataEmployees"
        [columnDefs]="colDefsEmployees"
        [stopEditingWhenCellsLoseFocus]="true"
        [singleClickEdit]="screenMode == 'audit'"
      >
      </ag-grid-angular>
    </div>
  </div>
  <div class="feedback-detail-section">
    <span>{{ "screen.contacts" | translate }}</span>
    <div class="open-feedbacks-container">
      <!-- The AG Grid component -->
      <ag-grid-angular
        class="ag-theme-alpine"
        [domLayout]="'autoHeight'"
        [rowData]="rowDataContacts"
        [columnDefs]="colDefsContacts"
        [stopEditingWhenCellsLoseFocus]="true"
        [singleClickEdit]="screenMode == 'audit'"
      >
      </ag-grid-angular>
    </div>
  </div>
  <div class="feedback-detail-section">
    <span>{{ "screen.qualityManagement" | translate }}</span>
    <div class="open-feedbacks-container">
      <!-- The AG Grid component -->
      <ag-grid-angular
        class="ag-theme-alpine"
        [domLayout]="'autoHeight'"
        [rowData]="rowDataQualityManagement"
        [columnDefs]="colDefsQualityManagement"
        [singleClickEdit]="screenMode == 'audit'"
      >
      </ag-grid-angular>
    </div>
  </div>
  <div class="feedback-detail-section">
    <span>{{ "screen.productionSpectre" | translate }}</span>
    <div class="open-feedbacks-container">
      <!-- The AG Grid component -->
      <ag-grid-angular
        class="ag-theme-alpine"
        [domLayout]="'autoHeight'"
        [rowData]="rowDataProductionSpectrum"
        [columnDefs]="colDefsProductionSpectrum"
        [singleClickEdit]="screenMode == 'audit'"
      >
      </ag-grid-angular>
    </div>
  </div>
  <div class="feedback-detail-section">
    <span>{{ "screen.admin.feedback-detail.comment-area" | translate }}</span>
    <div class="open-feedbacks-container">
      <textarea [disabled]="screenMode == 'display-only'" [(ngModel)]="assessmentComments" rows="5"></textarea>
    </div>
  </div>
  <app-button
    *ngIf="screenMode == 'audit'"
    [title]="auditAssessmentBtnConfig.title"
    [btnType]="auditAssessmentBtnConfig.btnType"
    [iconType]="auditAssessmentBtnConfig.btnIcon"
    [task]="auditAssessmentTask"
  ></app-button>
  <app-button
    *ngIf="screenMode == 'audit'"
    [title]="auditFinalizeAssessmentBtnConfig.title"
    [btnType]="auditFinalizeAssessmentBtnConfig.btnType"
    [iconType]="auditFinalizeAssessmentBtnConfig.btnIcon"
    [task]="auditFinalizeAssessmentTask"
  ></app-button>
</div>
