import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/service/auth.service";

@Component({
  selector: "app-finalization-end",
  templateUrl: "./finalization-end.component.html",
  styleUrls: ["./finalization-end.component.css"],
  host: {
    class: "supplier-overview",
  },
})
export class FinalizationEndComponent {
  constructor(private router: Router, private authService: AuthService) {
    authService.logout();
  }

  navigateBack(): void {
    this.router.navigate(["/login"]);
  }
}
