<div class="css-input-container">
  <span *ngIf="label && label.length > 0" class="css-input-label">
    {{ label | translate }}{{ getUnit() }}{{ required ? "*" : "" }}</span
  >
  <div style="display: flex; flex-direction: row">
    <select class="css-input-item mr-1" (change)="selectionChanged($event)">
      <option
        *ngFor="let option of countryCodes"
        [value]="option.code"
        [selected]="getCountryCode(value) === option.code"
      >
        {{ option.flag }} {{ option.code }}
      </option>
    </select>
    <input
      class="css-input-item"
      [type]="type"
      [name]="label"
      [id]="label"
      [value]="getPhoneNumber(value)"
      [min]="minValue"
      (keyup)="phoneNumberChanged($event)"
      (change)="phoneNumberChanged($event)"
      [ngClass]="{
        'field-required': required === true && (!value || value.length == 0)
      }"
    />
  </div>
  <!--
    <span *ngIf="showRequiredMarker()" class="field-required">
      {{ "general.field-required" | translate }}
    </span>
    -->
</div>
