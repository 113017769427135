import { Component, Input, OnInit } from '@angular/core';
import { IButton } from '../../buttons/button/button.component';

@Component({
  selector: 'app-footer-panel',
  templateUrl: './footer-panel.component.html',
  styleUrls: ['./footer-panel.component.css']
})
export class FooterPanelComponent implements OnInit {

  @Input()
  buttonConfigs: IButton[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
