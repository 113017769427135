import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appPasswordMatch]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatchDirective, multi: true }]
})
export class PasswordMatchDirective implements Validator {
  @Input('appPasswordMatch') MatchPassword: string[] = [];

  constructor() { }

  validate(formGroup: UntypedFormGroup): ValidationErrors | null {
    const pw: string = this.MatchPassword[0];
    const pwCheck: string = this.MatchPassword[1];

    const passwordControl = formGroup.controls[pw];
    const confirmPasswordControl = formGroup.controls[pwCheck];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }

      return null;
  }
}
