import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { JwtTokenService } from "../service/jwt-token.service";

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const jwtService = inject(JwtTokenService);
  const router = inject(Router);

  if (jwtService.getUser()) {
    if (!jwtService.isTokenExpired()) {
      return true;
    }
  }
  return router.createUrlTree(["/login"]);
};

export const canActivateLogin: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const jwtService = inject(JwtTokenService);
  const router = inject(Router);

  let validation = canActivate(route, state);
  if (validation === true) {
    router.createUrlTree(["/supplier"]);
  }
  return true;
};
