import { Component, Input, OnInit } from "@angular/core";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { Task } from "src/app/tasks/task";

@Component({
  selector: "app-header-panel-control",
  templateUrl: "./header-panel-control.component.html",
  styleUrls: ["./header-panel-control.component.css"],
})
export class HeaderPanelControlComponent implements OnInit {
  @Input()
  backTask: Task | null = null;

  @Input()
  homeTask?: Task;

  @Input()
  lastFinalization?: Date;

  constructor() {}

  homeBtnConfig = {
    title: "comp.title.home",
    icon: ButtonIconType.HOME,
  };

  customerLogoConfig = {
    src: ButtonIconType.CUSTOMER_LOGO
  }

  ngOnInit(): void {
    console.log(this.lastFinalization)
  }
}
