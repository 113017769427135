
<div class="supplier-overview-header">
    <div class="css-ui-line"></div>
    <div class="supplier-overview-header-img">
      <img src="assets/img/logos/customer-logo.png" alt="customer logo" />
    </div>
  </div>
  <div class="supplier-overview-body">
    <div class="supplier-overview-container">
      <div class="supplier-overview-controls">
        <h2>Ihre Firma wurde erfolgreich im DIRKRA Supplier Portal registriert!</h2>

        <p>Ihre Zugangsaten sowie weitere Informationen erhalten Sie per E-Mail.</p>
      </div>
    </div>
  </div>
  <div class="supplier-overview-psuedo-grow"></div>
  <div class="supplier-overview-footer">
    <div class="css-ui-line"></div>
  </div>
  


