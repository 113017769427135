<p style="width: 100%; text-align: center">
  Bitte wählen Sie alle akkreditierten Zertifikate die Ihr Unternehmen besitzt.
  Voraussetzung für die Auswahl eines der nachfolgenden Zertifikate ist ein
  entsprechender Nachweis in Form eines Dokumentenuploads.
</p>
<app-spinner [hidden]="!loadingFields"></app-spinner>
<div style="display: flex; flex-direction: column; flex: 1 0 0px">
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid rgb(55, 55, 55);
    "
    class="mb-3"
    *ngFor="let certificate of certificates; index as i"
  >
    <app-css-checkbox
      [value]="certificate"
      (valueChange)="qmCertificateChange($event, i)"
      style="width: 435px; display: flex"
    ></app-css-checkbox>
    <span *ngIf="certificate.files.length > 0"
      >Gültig bis: {{ certificate.validUntilDate }}</span
    >
    <div
      *ngIf="certificate.files.length > 0"
      style="display: flex; flex-direction: row; align-items: center"
    >
      <app-button
        [title]="'general.edit'"
        (click)="raiseEditDialog(certificate, i)"
      ></app-button>
    </div>
  </div>
  <app-button
    [title]="addCertificateBtnConfig.title"
    [btnType]="addCertificateBtnConfig.btnType"
    [iconType]="addCertificateBtnConfig.iconType"
    (click)="raiseAddDialog()"
  ></app-button>
</div>
