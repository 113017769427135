import { Component } from '@angular/core';

@Component({
  selector: 'app-registration-end',
  templateUrl: './registration-end.component.html',
  styleUrls: ['./registration-end.component.css']
})
export class RegistrationEndComponent {

}
