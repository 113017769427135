import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SUPPLIER_CONTACTS_FORM } from "src/app/controller/constants/forms-config";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import {
  PersonModalComponent,
  PersonModalDialogType,
} from "src/app/modal/contacts/person-modal/person-modal.component";
import { AbstractFormComponent } from "../abstract-form.component";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.css"],
  host: {
    class: "css-form",
  },
})
export class ContactsComponent extends AbstractFormComponent implements OnInit {
  countryCodes: any[] = [];

  contactCategories: any[] = [];
  activeContactCategory: any | null = null;
  newContactCategory: string = "";

  addContactCategoryBtn: any = {
    title: "comp.title.add",
    btnType: ButtonType.SECONDARY,
    iconType: ButtonIconType.ADD,
  };

  removeContactCategoryBtn: any = {
    btnType: ButtonType.PRIMARY,
    iconType: ButtonIconType.REMOVE,
  };

  removePersonBtn: any = {
    iconType: ButtonIconType.REMOVE,
  };

  constructor(private dialog: MatDialog, httpClient: HttpClient) {
    super();
    this.http = httpClient;
  }

  ID: number = SUPPLIER_CONTACTS_FORM.ID;
  TITLE: string = SUPPLIER_CONTACTS_FORM.TITLE;
  PATH: string = SUPPLIER_CONTACTS_FORM.PATH;

  selectContactCategory(contactCategory: any) {
    this.activeContactCategory = contactCategory;
  }

  raiseEditPersonDialog(person: any, index: number) {
    this.raisePersonDialog(PersonModalDialogType.EDIT, person, index);
  }

  raiseAddPersonDialog() {
    this.raisePersonDialog(PersonModalDialogType.ADD, null, 0);
  }

  async raisePersonDialog(dialogType: PersonModalDialogType, person: any, index: number) {
    if (!this.countryCodes || this.countryCodes.length == 0) {
      await this.loadCountryCodes();
    }
    const dialogRef = this.dialog.open(PersonModalComponent, {
      data: {
        source: this,
        dialogType: dialogType,
        countryCodes: this.countryCodes,
        person: person,
        index: index
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log("The dialog was closed");
    });
  }

  async loadCountryCodes() {
    if (!this.http) {
      return;
    }
    let countries: any[] = (await this.http
      ?.get("https://restcountries.com/v3.1/all")
      .toPromise()) as any[];
    let countryCodesTmp = countries
      .filter(
        (c) =>
          c.idd && c.idd.root && c.idd.suffixes && c.idd.suffixes.length > 0
      )
      .map((country) => ({
        flag: country.flag,
        code: `${country.idd.root}${country.idd.suffixes[0]}`,
      }))
      .sort((o1: any, o2: any) => o1.code - o2.code);

    this.countryCodes = countryCodesTmp.filter((element, index) => {
      return countryCodesTmp.indexOf(element) === index;
    });
  }
  
  addContactCategory() {
    this.contactCategories.push({
      ident: `custom-${this.newContactCategory}`,
      name: this.newContactCategory,
      persons: []
    });
  }

  removeContactCategory(index: number) {
    this.contactCategories.splice(index, 1);
  }

  addPerson(person: any) {
    this.activeContactCategory.persons.push(person);
  }

  editPerson(person: any, index: number) {
    this.removePerson(index);
    this.activeContactCategory.persons.splice(index, 0, person);
  }


  removePerson(index: number) {
    this.activeContactCategory.persons.splice(index, 1);
  }

  getFormValues() {
    return this.contactCategories;
  }

  setFormValues(formValues: any): void {
    this.contactCategories = formValues;
  }

  getSheetName(): string {
    return "contacts";
  }

  getPersonBtnTitle(person: any) {
    return `${person.fName.substring(0, 1)}. ${person.lName}, ${
      person.position
    }`;
  }

  ngOnInit(): void {}
}
