<div style="display: flex; flex-direction: row; width: 250px; gap: 0.2em">
  <button
    *ngIf="mode == 0"
    class="btn css-btn css-btn-primary"
    style="margin: 0.3em 0"
    (click)="edit()"
  >
    <div class="css-btn-title-container">
      <div *ngIf="iconType" class="css-clip-path-icon-container">
        <i *ngIf="!useImageAsIcon" [ngClass]="getIconClass()"></i>
        <img
          *ngIf="useImageAsIcon"
          style="height: 2em"
          [src]="iconType"
          alt="customer image"
        />
      </div>
      <span class="css-btn-title ml-2">{{ title | translate }}</span>
    </div>
  </button>
  <button
    *ngIf="mode == 1"
    class="btn css-btn css-btn-primary"
    style="margin: 0.3em 0"
    (click)="save()"
  >
    <div class="css-btn-title-container">
      <div *ngIf="iconType" class="css-clip-path-icon-container">
        <i *ngIf="!useImageAsIcon" [ngClass]="getIconClass()"></i>
        <img
          *ngIf="useImageAsIcon"
          style="height: 2em"
          [src]="iconType"
          alt="customer image"
        />
      </div>
      <span class="css-btn-title ml-2">{{ "general.save" | translate }}</span>
    </div>
  </button>
  <button
    *ngIf="mode == 1"
    class="btn css-btn css-btn-secondary"
    (click)="cancel()"
    style="margin: 0.3em 0"
  >
    <div class="css-btn-title-container">
      <span class="css-btn-title ml-2">{{ "general.cancel" | translate }}</span>
    </div>
  </button>
</div>
