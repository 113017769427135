
<div class="supplier-overview-header">
    <div class="css-ui-line"></div>
    <div class="supplier-overview-header-img">
      <img src="assets/img/logos/customer-logo.png" alt="customer logo" />
    </div>
  </div>
  <div class="supplier-overview-body">
    <div class="supplier-overview-container">
      <div class="supplier-overview-controls">
        <h2>Vielen Dank für Ihre Kooperation!</h2>

        <p>Ihre Informationen wurden erfolgreich übertragen und sind für uns von großer Bedeutung, um eine reibungslose Zusammenarbeit sicherzustellen. Sie wurden automatisch abgemeldet.</p>
        <app-button [title]="'general.back'" (click)="navigateBack()"></app-button>
      </div>
    </div>
  </div>
  <div class="supplier-overview-psuedo-grow"></div>
  <div class="supplier-overview-footer">
    <div class="css-ui-line"></div>
  </div>
  


