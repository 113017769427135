import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ButtonComponent } from "src/app/components/buttons/button/button.component";
import { SUPPLIER_QM_FORM } from "src/app/controller/constants/forms-config";
import { ButtonIconType } from "src/app/enums/button-icon-type.enum";
import { ButtonType } from "src/app/enums/button-type.enum";
import { ModalButtonGenerator } from "src/app/modal/buttons/impl/modal-button-generator";
import {
  CertificatesModalComponent,
  CertificatesModalType,
} from "src/app/modal/certificates-modal/certificates-modal.component";
import { ModalComponent } from "src/app/modal/modal.component";
import { AssessmentService } from "src/app/services/api/assessment.service";
import { FormServiceService } from "src/app/services/form-service.service";
import { Task } from "src/app/tasks/task";
import { AbstractFormComponent } from "../abstract-form.component";

@Component({
  selector: "app-quality-management",
  templateUrl: "./quality-management.component.html",
  styleUrls: ["./quality-management.component.css"],
  host: {
    class: "css-form",
  },
})
export class QualityManagementComponent
  extends AbstractFormComponent
  implements OnInit
{
  certificates: any = [];
  certificateToRemove: any = null;

  addCertificateBtnConfig: any = {
    title: "comp.title.add",
    btnType: ButtonType.SECONDARY,
    iconType: ButtonIconType.ADD,
  };

  constructor(
    http: HttpClient,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private assessmentService: AssessmentService,
    private formService: FormServiceService
  ) {
    super(http);
  }

  ID: number = SUPPLIER_QM_FORM.ID;
  TITLE: string = SUPPLIER_QM_FORM.TITLE;
  PATH: string = SUPPLIER_QM_FORM.PATH;

  getFormValues() {
    return this.certificates;
  }

  setFormValues(formValues: any): void {
    this.certificates = formValues;
    if (this.certificates) {
      this.certificates.forEach((certificate: any) => {
        this.fixCertificateForGUI(certificate);
      });
    }
  }

  qmCertificateChange(certificate: any, index: number) {
    if (certificate.value == true) {
      this.raiseEditDialog(certificate, index);
    } else {
      this.raiseRemoveConfirmationDialog(certificate, index);
    }
  }

  fixCertificateForGUI(certificate: any) {
    if (certificate.files && certificate.files.length > 0) {
      certificate.checked = true;
    } else {
      certificate.checked = false;
    }

    if (!certificate.ident) {
      return;
    }

    if (!certificate.label || certificate.label.length == 0) {
      certificate.label = "screen.certificates." + certificate.ident;
    }
    if (certificate.ident.startsWith("custom-")) {
      certificate.label = certificate.name;
    }
  }

  raiseAddDialog() {
    let certificate = {
      ident: null,
      name: null,
      files: [],
      validUntilDate: null,
    };

    this.raiseCertificateDialog(certificate, -1, CertificatesModalType.ADD);
  }

  raiseEditDialog(certificate: any, index: number) {
    this.raiseCertificateDialog(certificate, index, CertificatesModalType.EDIT);
  }

  raiseCertificateDialog(
    certificate: any,
    index: number,
    dialogType: CertificatesModalType
  ) {
    let cancelDialogTask = new CancelCertificateDialogTask(
      this,
      certificate,
      index
    );
    const dialogRef = this.dialog.open(CertificatesModalComponent, {
      data: {
        certificate: certificate,
        source: this,
        message: this.translateService.instant("general.form-required-fields"),
        index: index,
        dialogType: dialogType,
        buttons: [
          ModalButtonGenerator.createButton(
            "general.close",
            cancelDialogTask,
            ButtonType.SECONDARY
          ),
        ],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log("The dialog was closed");
    });
  }

  raiseRemoveConfirmationDialog(certificate: any, index: number) {
    this.certificateToRemove = certificate;
    let removeTask = new RemoveCertificateTask(this, index);
    const dialogRef = this.dialog.open(ModalComponent, {
      data: {
        message: this.translateService.instant(
          "screen.certificates.remove-certificate-confirmation"
        ),
        buttons: [
          ModalButtonGenerator.createCancelButton(),
          ModalButtonGenerator.createOkButton(removeTask),
        ],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log("The dialog was closed");
    });
  }

  async removeCertificate(index: number) {
    this.certificateToRemove.files = [];
    this.certificateToRemove.validUntilDate = null;
    await this.updateCertificate(this.certificateToRemove, index);
    this.certificateToRemove = null;
  }

  getSheetName(): string {
    return "certificates";
  }

  async updateCertificate(certificate: any, index: number) {
    if (index >= 0) {
      this.certificates.splice(index, 1);
      this.certificates.splice(index, 0, certificate);
    } else {
      this.fixCertificateForGUI(certificate);
      this.certificates.push(certificate);
    }
    await this.assessmentService.updateCertificate(certificate).toPromise();
    await this.formService.loadForm(this); // reload form
  }

  ngOnInit(): void {}
}
class RemoveCertificateTask implements Task {
  constructor(
    private source: QualityManagementComponent,
    private indexToRemove: number
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    await this.source.removeCertificate(this.indexToRemove);
    dialogRef?.close();
  }
}
class CancelCertificateDialogTask implements Task {
  constructor(
    private source: QualityManagementComponent,
    private certificate: any,
    private index: number
  ) {}

  async execute(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined,
    buttonComponent?: ButtonComponent | undefined
  ): Promise<void> {
    // force server to remove unsaved but uploaded files
    await this.source.updateCertificate(this.certificate, this.index);
    dialogRef?.close();
  }
}
