import { Component, OnInit } from "@angular/core";
import { ICategoryTitleService } from "src/app/components/master-detail/button-to-inputs/category-title-service.interface";
import { SUPPLIER_PRODUCTIONSPECTRE_FORM } from "src/app/controller/constants/forms-config";
import { ProdSpectrumService } from "src/app/services/api/prod-spectrum.service";
import { AbstractFormComponent } from "../abstract-form.component";

@Component({
  selector: "app-production-spectre",
  templateUrl: "./production-spectre.component.html",
  styleUrls: ["./production-spectre.component.css"],
  host: {
    class: "css-form",
  },
})
export class ProductionSpectreComponent
  extends AbstractFormComponent
  implements OnInit, ICategoryTitleService
{
  form: any = [];
  selectableProductionSpectrums: any = {};

  constructor(private prodSpectrumService: ProdSpectrumService) {
    super();
    this.prodSpectrumService.findAll().subscribe((val: any) => {
      if (val && Object.keys(val).length > 0) {
        this.selectableProductionSpectrums["spectrum"] = [];
        this.fixValuesForGUI(
          val,
          this.selectableProductionSpectrums["spectrum"]
        );
        this.updateRequiredFields(val);
      }
    });
  }

  ID: number = SUPPLIER_PRODUCTIONSPECTRE_FORM.ID;
  TITLE: string = SUPPLIER_PRODUCTIONSPECTRE_FORM.TITLE;
  PATH: string = SUPPLIER_PRODUCTIONSPECTRE_FORM.PATH;

  fixValuesForGUI(inValues: any[], outValues: any[]) {
    if (!outValues) {
      outValues = [];
    }

    if (inValues && inValues.length > 0) {
      inValues.forEach((productionSpectrum: any) => {
        let productionSpectrumName: string = `${productionSpectrum["name"]}`;
        if (productionSpectrumName && productionSpectrumName.length > 0) {
          if (
            !productionSpectrumName.toLocaleLowerCase().startsWith("custom-")
          ) {
            productionSpectrumName = productionSpectrumName
              .replace(" ", "-")
              .toLowerCase();
            productionSpectrum[
              "label"
            ] = `screen.productionSpectre-labels.${productionSpectrumName}`;
          } else {
            productionSpectrum["label"] = productionSpectrumName;
          }
          if (
            productionSpectrum &&
            productionSpectrum["parameter"] &&
            productionSpectrum["parameter"].length > 0
          ) {
            let productionSpectrumParamter: any[] =
              productionSpectrum["parameter"];
            productionSpectrumParamter.forEach((pSP) => {
              let productionSpectrumParamterName = `${pSP["name"]}`;
              if (
                productionSpectrumParamterName &&
                productionSpectrumParamterName.length > 0
              ) {
                if (
                  !productionSpectrumParamterName
                    .toLocaleLowerCase()
                    .startsWith("custom-")
                ) {
                  productionSpectrumParamterName =
                    productionSpectrumParamterName
                      .replace(" ", "-")
                      .toLowerCase();
                  pSP[
                    "label"
                  ] = `screen.productionSpectre-labels.categoryDetails.${productionSpectrumParamterName}`;
                } else {
                  pSP["label"] = productionSpectrumParamterName;
                }

                if (pSP.unit == 'checkbox') {
                  pSP.value = {
                    checked: pSP.value,
                    label: pSP['label']
                  }
                }
              }
            });
          }
          outValues.push(productionSpectrum);
        }
      });
    }
  }

  updateRequiredFields(fields: any[]) {
    if (fields && fields.length > 0) {
      fields.forEach((productionSpectre: any) => {
        if (
          productionSpectre["parameter"] &&
          productionSpectre["parameter"].length > 0
        ) {
          productionSpectre["parameter"].forEach((productionParameter: any) => {
            let index = this.requiredFields.indexOf(productionParameter);
            if (
              productionParameter.mandatory === true &&
              (!productionParameter.value ||
                productionParameter.value.length == 0)
            ) {
              if (index == -1) {
                this.requiredFields.push(`${productionSpectre.name}-${productionParameter.name}`);
              }
            } else {
              if (index > -1) {
                this.requiredFields.splice(index, 1);
              }
            }
          });
        }
      });
    }
  }

  getRequiredFormValues() {
    let formValues: any = [];
    if (this.form && this.form.length > 0) {
      this.form.forEach((productionSpectre: any) => {
        if (
          productionSpectre["parameter"] &&
          productionSpectre["parameter"].length > 0
        ) {
          productionSpectre["parameter"].forEach((productionParameter: any) => {
            formValues[
              `${productionSpectre.name}-${productionParameter.name}`
            ] = productionParameter.value;
          });
        }
      });
    }
    return formValues;
  }

  getFormValues() {
    return this.form;
  }

  setFormValues(formValues: any, availableFormValues: any): void {
    this.form = [];
    this.fixValuesForGUI(formValues, this.form);
  }

  getCategoryTitle(category: string): string {
    return `screen.productionSpectre-labels.${category}`;
  }

  getCategoryDetailTitle(category: string, categoryDetail: string): string {
    return `screen.productionSpectre-labels.categoryDetails.${categoryDetail}`;
  }

  getSheetName(): string {
    return "productionSpectrum";
  }

  ngOnInit(): void {}
}
