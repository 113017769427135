import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ButtonComponent } from "src/app/components/buttons/button/button.component";
import { ModalComponent } from "src/app/modal/modal.component";
import { SaveSupplierFormTask } from "./save-supplier-form.task";
import { TranslateService } from "@ngx-translate/core";
import { FormServiceService } from "src/app/services/form-service.service";
import { Router } from "@angular/router";



export class FinalizeAssessmentTask extends SaveSupplierFormTask {

    constructor(
        dialog: MatDialog,
        translate: TranslateService,
        formService: FormServiceService,
        showSuccessDialog: boolean = true,
        private router: Router,
    ) {
        super(dialog, translate, formService, showSuccessDialog);
    }

protected async executeAction(dialogRef?: MatDialogRef<ModalComponent, any> | undefined): Promise<void> {
    super.executeAction(dialogRef);
    this.router.navigate(["supplier-form", "finalization"]);
    
}
}