<mat-dialog-content>
  <h4>
    {{
      "screen.admin.user-management.manage-users.reset-password.title"
        | translate
    }}
  </h4>
  <app-css-text-input
    [type]="'password'"
    [label]="
      'screen.admin.user-management.manage-users.reset-password.description'
    "
    [(value)]="password"
  ></app-css-text-input>
</mat-dialog-content>
<mat-dialog-actions class="custom-mat-dialog-actions">
  <div class="modal-dialog-footer" *ngIf="!taskLoading">
    <app-button
      *ngFor="let button of buttons"
      [btnType]="button.type"
      [dialogRef]="dialogRef"
      (click)="executeTask(button.task)"
      [title]="button.text"
      [customClasses]="'css-modal-button'"
    ></app-button>
  </div>
  <div class="modal-dialog-footer" *ngIf="taskLoading">
    <button class="btn btn-custom btn-outline">
      <div class="spinner-border spinner-border-sm"></div>
    </button>
  </div>
</mat-dialog-actions>
