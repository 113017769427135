<p style="width: 100%; text-align: center">
  Bitte wählen Sie die Auslastung der Fertigungseinrichtungen.
</p>
<app-spinner [hidden]="!loadingFields"></app-spinner>
<div [hidden]="loadingFields" class="utilization-form-container">
  <app-css-radio-button
    [options]="fields"
    [radioName]="utilizationRateOptionName"
    [title]="utilizationRateOptionTitle"
    [(value)]="form['utilizationRate']"
  ></app-css-radio-button>
  <app-css-text-input
    [label]="daysPerWeekInput.label"
    [(value)]="form['daysPerWeek']"
  ></app-css-text-input>
</div>
