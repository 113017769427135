import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Logger } from "src/app/logger";

@Component({
  selector: "app-css-text-input",
  templateUrl: "./css-text-input.component.html",
  styleUrls: ["./css-text-input.component.css"],
})
export class CssTextInputComponent implements OnInit {
  logger = new Logger();

  @Input()
  disabled: boolean = false;

  @Input()
  minValue: number = 0;

  @Input()
  maxValue?: number;

  @Input()
  label: string = "";

  @Input()
  value: string = "";

  @Input()
  unit?: string;

  @Input()
  type: string = "text";

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  required: boolean = false;

  @Input()
  placeholder: string = "";

  constructor() {}

  ngOnInit(): void {}

  valueChanged(event: any) {
    this.logger.log("valueChanged", event.target.value);
    this.valueChange.emit(event.target.value);
  }

  getUnit() {
    return this.unit ? " (" + this.unit + ")" : "";
  }
}
