import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit {

  logoUrl: string = environment.CUSTOMER_LOGO_URL;

  constructor() { }

  ngOnInit(): void {
  }

}
