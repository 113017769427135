<button
  class="btn css-btn"
  [ngClass]="getBtnClass()"
  (click)="executeTask()"
  tabindex="1"
  [disabled]="taskLoading"
  [style]="cssStyle"
>
  <div *ngIf="enableBackwardIcon" class="css-clip-path-icon-container">
    <div class="css-clip-path-icon css-backward-icon"></div>
  </div>
  <div class="css-btn-title-container">
    <div *ngIf="iconType" class="css-clip-path-icon-container">
      <i *ngIf="!useImageAsIcon" [ngClass]="getIconClass()"></i>
      <img *ngIf="useImageAsIcon" style="height: 2em;" [src]="iconType" alt="customer image"/>
    </div>
    <span *ngIf="title && title.length > 0" class="css-btn-title ml-2">{{ title | translate }}</span>
  </div>
  <div *ngIf="enableForwardIcon" class="css-clip-path-icon-container">
    <div class="css-clip-path-icon css-forward-icon"></div>
  </div>
  <div
    *ngIf="taskLoading"
    class="ml-2 spinner-border spinner-border-sm"
    style="width: 1.5em; height: 1.5em;"
  ></div>
</button>
