import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SocketioService {
  socket!: Socket;
  public socketInitialzied = false;

  private loadingSubject: BehaviorSubject<boolean>;
  public loadingObs: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private router: Router
  ) {
    this.loadingSubject = new BehaviorSubject<boolean>(true);
    this.loadingObs = this.loadingSubject.asObservable();
  }

  async setupSocketConnection() {
    const token = this.tokenStorage.getToken();
    // this.initSocketConnection(token);
    this.socketInitialzied = true;
  }

  private initSocketConnection(vtoken: any) {
    this.socket = io(environment.SOCKET_ENDPOINT, {
      query: {
        token: vtoken,
      },
    });

    this.registerSocketListener();
  }

  registerSocketListener() {
    this.socket.on('connect', () => {
      console.log("Connected");
    });

    this.socket.on('forcelogout', () => {
      this.tokenStorage.signOut();
      this.logout();
      this.router.navigate(['login']);
    })
  }

  logout() {
    this.socket.disconnect();
  }
}
