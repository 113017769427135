import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { HttpEventType, HttpResponse } from "@angular/common/http";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { UploadFilesService } from "src/app/services/api/upload-files.service";

@Component({
  selector: "app-upload-files",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.css"],
})
export class UploadFileComponent implements OnInit {
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = "";

  @Input()
  fileIdent = "";

  @Input()
  fileName = "";

  @Output()
  uploadFinished = new EventEmitter<any>();

  fileInfos?: Observable<any>;

  constructor(private uploadService: UploadFilesService) {}

  ngOnInit(): void {
    this.fileInfos = this.uploadService.getFiles();
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        if (!this.fileIdent) {
          this.fileIdent = `custom-${this.fileName}`;
        }
        this.uploadService.uploadCertificate(this.currentFile, this.fileIdent).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              let lastModified = file.lastModified;
              let fileA: any = file;
              let lastModifiedDate = fileA.lastModifiedDate;
              let uploadFinishObj = {
                lastModified,
                lastModifiedDate,
                ...event.body,
              };
              this.uploadFinished.emit(uploadFinishObj);
              this.message = event.body.message;
              this.fileInfos = this.uploadService.getFiles();
              setTimeout(() => {
                this.currentFile = undefined;
              }, 3000);
            }
          },
          (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = "Could not upload the file!";
            }

            this.currentFile = undefined;
          }
        );
      }

      // this.selectedFiles = undefined;
    }
  }

  dateInput = new UntypedFormControl("", [
    Validators.required,
    Validators.pattern("^(0[1-9]|1[0-2])\\.[0-9]{4}$"),
  ]);

  get formattedDate() {
    const value = this.dateInput.value;

    if (value.length === 2 && !value.includes(".")) {
      return value + ".";
    } else if (value.length === 3) {
      return value.replace(".", "");
    } else if (value.length > 2 && !value.includes(".")) {
      return value.slice(0, 2) + "." + value.slice(2);
    }
    return value;
  }

  onInputChange(event: any) {
    try {
      if (parseInt(event)) {
        const input = event.replace(/\D/g, "");
        this.dateInput.setValue(input, { emitEvent: false });
      } else {
        this.dateInput.setValue("");
      }
    } catch (error) {
      this.dateInput.setValue("");
    }
  }
}
