import { IFormComponent } from "src/app/interfaces/form-component.interface";
import { IScreenConfig } from "src/app/interfaces/screen-config.interface";

export const SUPPLIER_GENERAL_FORM: IFormComponent = {
  ID: 1,
  TITLE: "screen.general",
  PATH: "general",
};
export const SUPPLIER_EMPLOYEES_FORM: IFormComponent = {
  ID: 2,
  TITLE: "screen.employees",
  PATH: "employees",
};
export const SUPPLIER_CONTACTS_FORM: IFormComponent = {
  ID: 3,
  TITLE: "screen.contacts",
  PATH: "contacts",
};
export const SUPPLIER_UTILIZATIONRATE_FORM: IFormComponent = {
  ID: 4,
  TITLE: "screen.utilizationRateProductionLines",
  PATH: "utilizationRateProductionLines",
};
export const SUPPLIER_QM_FORM: IFormComponent = {
  ID: 5,
  TITLE: "screen.qualityManagement",
  PATH: "qualityManagement",
};
export const SUPPLIER_PRODUCTIONSPECTRE_FORM: IFormComponent = {
  ID: 6,
  TITLE: "screen.productionSpectre",
  PATH: "productionSpectre",
};
export const SUPPLIER_ASSESSMENT_FINALIZATION: IFormComponent = {
  ID: 7,
  TITLE: "screen.finalization",
  PATH: "finalization",
};

export const amountSupplierForms = 6.0;

export const supplierFormConfig: IScreenConfig[] = [
  {
    id: SUPPLIER_GENERAL_FORM.ID,
    title: SUPPLIER_GENERAL_FORM.TITLE,
    path: SUPPLIER_GENERAL_FORM.PATH,
    progressValue: 1 / amountSupplierForms,
    backRoute: null,
    nextScreen: 2,
  },
  {
    id: SUPPLIER_EMPLOYEES_FORM.ID,
    title: SUPPLIER_EMPLOYEES_FORM.TITLE,
    path: SUPPLIER_EMPLOYEES_FORM.PATH,
    progressValue: 2 / amountSupplierForms,
    backRoute: 1,
    nextScreen: 3,
  },
  {
    id: SUPPLIER_CONTACTS_FORM.ID,
    title: SUPPLIER_CONTACTS_FORM.TITLE,
    path: SUPPLIER_CONTACTS_FORM.PATH,
    progressValue: 3 / amountSupplierForms,
    backRoute: 2,
    nextScreen: 5,
  },
  /*
  {
    id: SUPPLIER_UTILIZATIONRATE_FORM.ID,
    title: SUPPLIER_UTILIZATIONRATE_FORM.TITLE,
    path: SUPPLIER_UTILIZATIONRATE_FORM.PATH,
    progressValue: 4 / amountSupplierForms,
    backRoute: 3,
    nextScreen: 5,
  },
  */
  {
    id: SUPPLIER_QM_FORM.ID,
    title: SUPPLIER_QM_FORM.TITLE,
    path: SUPPLIER_QM_FORM.PATH,
    progressValue: 5 / amountSupplierForms,
    backRoute: 3,
    nextScreen: 6,
  },
  {
    id: SUPPLIER_PRODUCTIONSPECTRE_FORM.ID,
    title: SUPPLIER_PRODUCTIONSPECTRE_FORM.TITLE,
    path: SUPPLIER_PRODUCTIONSPECTRE_FORM.PATH,
    progressValue: 6 / amountSupplierForms,
    backRoute: 5,
    nextScreen: 7,
  },
  {
    id: SUPPLIER_ASSESSMENT_FINALIZATION.ID,
    title: SUPPLIER_ASSESSMENT_FINALIZATION.TITLE,
    path: SUPPLIER_ASSESSMENT_FINALIZATION.PATH,
    progressValue: 6 / amountSupplierForms,
    backRoute: 6,
    nextScreen: null,
  },
];
